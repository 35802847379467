import React from 'react';
// REDUX IMPORTS
import { useSelector } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses
} from '@material-ui/core';
import {
    Announcement, ArrowDownward, ArrowUpward, Assignment,
    Check, Comment,
    ThumbUp,
} from '@material-ui/icons';
// NPM IMPORTS
import moment from "moment";
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------------------------------------------

ActivitySummary.propTypes = {
    theme: PropTypes.string,
    themeColor: PropTypes.object
}


export default function ActivitySummary({ theme, themeColor }) {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REDUX STATES
    const activity = useSelector(state => state?.empActivity);
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();
    // REACT STATES
    const [data, setData] = React.useState([]);
    const [date, setDate] = React.useState(moment(new Date()).format("YYYY, DD, MM"));
    const [tableOrder, setTableOrder] = React.useState(false);
    const [showIcon, setShowIcon] = React.useState(false);

    React.useEffect(() => {
        if (activity?.result?.length > 0) {
            setData(activity?.result?.reverse())
        }
    }, [activity])

    const handleTableOrder = () => {
        setData(data?.reverse());
        setTableOrder(!tableOrder);
    }
    // console.log(activity?.result)

    return (
        <>
            <TableContainer sx={{ py: 2, px: 1, borderRadius: '5px' }}>
                <Table size='small' sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{
                            [`& .${tableCellClasses.root}`]: {
                                py: 1
                            },
                        }}>
                            <TableCell>
                                <Box onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>
                                        Latest Activity
                                    </Typography>&nbsp;
                                    {showIcon && <IconButton onClick={() => handleTableOrder()} >
                                        {tableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}
                                    </IconButton>}

                                </Box>
                            </TableCell>
                            <TableCell align="right">

                                {loggedUserData?.staff?.staffType === 10 && <Link to="/employee/activity" style={{ textDecoration: "none", color: theme === 'light' ? themeColor?.main : themeColor?.dark }}>

                                    View All Activity

                                </Link>}
                                {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={`/employee/activity?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=False`} style={{ textDecoration: "none", color: theme === 'light' ? themeColor?.main : themeColor?.dark }}>

                                    View All Activity

                                </Link>}
                                {!loggedUserData?.staff && <Link to={`/employee/activity?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=True`} style={{ textDecoration: "none", color: theme === 'light' ? themeColor?.main : themeColor?.dark }}>

                                    View All Activity

                                </Link>}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {data?.length > 0 && <TableBody>
                        {data?.map((row, index) => <TableRow
                            key={index}
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                    py: 2,
                                    borderBottom: (theme) => `1px solid ${(index !== activity?.result?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                },
                            }}
                        >
                            <TableCell align="left" component="th" scope="row" sx={{ width: "65%" }}>
                                <Box display="flex" alignItems="center" alignContent="center">
                                    {(row.type === 10 || row.type === 20) && <Check sx={{ mr: 1 }} />}
                                    {row.type === 30 && <ThumbUp sx={{ mr: 1 }} />}
                                    {row.type === 30 && <ThumbUp sx={{ mr: 1 }} />}
                                    {row.type === 40 && <Comment sx={{ mr: 1 }} />}
                                    {row.type === 50 && <Announcement sx={{ mr: 1 }} />}
                                    <Typography sx={{ fontSize: "13px" }}>
                                        {row?.action}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center" component="th" scope="row" sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 1 && "Today"}
                                {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') === 1 && "1 Day Ago"}
                                {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 7 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') > 1) && `${moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days')} days ago`}
                                {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 7 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 14) && "1 week ago"}
                                {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 14 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 28) && "2 week ago"}
                                {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 28 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 30) && "3 week ago"}
                                {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 30 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 60) && "1 month ago"}
                                {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 60 && moment(row?.created_at).format("LL")}
                            </TableCell>
                        </TableRow>)}
                    </TableBody>}
                </Table>
                {
                    data?.length === 0 && <Box sx={{ width: "90%", minHeight: "100px", py: 2 }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                        <Assignment sx={{ fontSize: "50px" }} /><br />
                        <Typography variant="body2" sx={{ my: 1 }}>
                            No Activity found for the data range selected.
                        </Typography>
                    </Box>
                }
            </TableContainer >
        </>
    )
}