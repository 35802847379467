import React from 'react';
// REDUX IMPORTS
import { useSelector, useDispatch } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Button,
    Box,
    IconButton,
    Table,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip
} from '@material-ui/core';
import { List, ArrowUpward, ArrowDownward } from '@material-ui/icons';
// NPM IMPORTS
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import moment from "moment";
// COMPONENT IMPORTS
import { MIconButton } from '../../../components/@material-extend';
// API IMPORTS
import { editTaskApi } from "../../../_apis_/authApi.js/staffApi";
// REDUX ACTION IMPORTS
import { editTasks } from "../../../redux/action/staffAction";
// UIL IMPORTS
import { getTaskStatus, setTaskPriority } from "../../../utils/filterUtilities";

// -----------------------------------------------------------------------------------------
EmpTaskSummary.propTypes = {
    theme: PropTypes.string,
    themeColor: PropTypes.object
}

export default function EmpTaskSummary({ theme, themeColor }) {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REDUX IMPORTS
    const dispatch = useDispatch();
    const taskData = useSelector(state => state.tasks);
    // REACT STATES
    const [task, setTask] = React.useState([]);
    const [date, setDate] = React.useState(moment(new Date()).format("YYYY, DD, MM"));
    const [showIcon, setShowIcon] = React.useState(false)
    const [tableOrder, setTableOrder] = React.useState(false)
    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();




    React.useEffect(() => {
        if (taskData) {
            setTask(taskData?.results?.slice((taskData?.results?.length - 3), taskData?.results?.length))
        }
    }, [taskData])
    // console.log(taskData)

    const handleTableOrder = () => {
        setTableOrder(!tableOrder);
        setTask(task?.reverse());
    }

    const editTaskFn = async (index, id) => {
        const newObj = {
            name: task[index]?.name,
            description: task[index]?.description,
            due_date: task[index]?.due_date,
            task_status: id || task[index]?.task_status,
            task_priority: task[index]?.task_priority,
            is_archive: task[index]?.is_archive,
            staff: task[index]?.staff
        }
        const { data } = await editTaskApi(task[index]?.id, newObj)
        dispatch(editTasks(data));

        enqueueSnackbar('Updated Task Successfully', {
            variant: 'success',
            action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                </MIconButton>
            )
        });
    }
    return (
        <Box>
            <TableContainer sx={{ py: 2, px: 1, borderRadius: '5px' }}>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell onMouseLeave={() => setShowIcon(false)} onMouseEnter={() => setShowIcon(true)} >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "16px", color: theme => theme.palette.text.primary }}>
                                        Task
                                    </Typography>&nbsp;
                                    {showIcon && <Tooltip title='Sort'>
                                        <IconButton onClick={() => handleTableOrder()}>
                                            {tableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}
                                        </IconButton>
                                    </Tooltip>}
                                </Box>

                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13px", color: theme => theme.palette.text.primary }}>
                                    Priority
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13px", color: theme => theme.palette.text.primary }}>
                                    Status
                                </Typography>
                            </TableCell>
                            {loggedUserData?.userLevel?.name !== 'Dashboard Only' && <TableCell />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {task?.length > 0 && task?.map((row, index) => <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                            <TableCell align="left" component="th" scope="row" >
                                <Box>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        {row?.name}
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                        <h5 style={{ fontSize: "11px" }}>Due Date:</h5>
                                        <h5 style={{ fontSize: "11px" }}>{moment(row?.due_date).format("LL")}</h5>
                                        <h5 style={{ fontSize: "11px" }}>
                                            {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 1 && "Today"}
                                            {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') === 1 && "1 Day Ago"}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 7 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') > 1) && `${moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days')} days ago`}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 7 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 14) && "1 week ago"}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 14 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 28) && "2 week ago"}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 28 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 30) && "3 week ago"}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 30 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 60) && "1 month ago"}
                                            {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 60 && moment(row?.created_at).format("LL")}
                                        </h5>
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell align="left" component="th" scope="row">
                                <Button sx={{ cursor: "default", fontSize: "13px", color: "white", paddingY: "2px", backgroundColor: setTaskPriority(row?.task_priority)?.bgColor, "&:hover": { backgroundColor: setTaskPriority(row?.task_priority)?.bgColor } }}>
                                    {setTaskPriority(row?.task_priority)?.priorityName}
                                </Button>
                            </TableCell>
                            <TableCell align="left" component="th" scope="row">
                                <Button sx={{ cursor: "default", fontSize: "13px", borderRadius: 1, paddingY: "2px", color: "white", backgroundColor: getTaskStatus(row?.task_status)?.bgColor, "&:hover": { backgroundColor: getTaskStatus(row?.task_status)?.bgColor } }}>
                                    {getTaskStatus(row?.task_status)?.statusName}
                                </Button>
                            </TableCell>
                            {loggedUserData?.userLevel?.name !== 'Dashboard Only' && <TableCell align="center" component="th" scope="row">
                                {row?.task_status === 10 && <Button disabled={loggedUserData?.userLevel?.name === 'Dashboard Only'} variant="outlined" sx={{ fontSize: "13px" }} onClick={() => { editTaskFn(index, 20) }}>
                                    Start Task
                                </Button>}
                                {row?.task_status === 20 && <Button disabled={loggedUserData?.userLevel?.name === 'Dashboard Only'} variant="outlined" sx={{ fontSize: "13px" }} onClick={() => { editTaskFn(index, 30) }}>
                                    Complete Task
                                </Button>}
                                {row?.task_status === 30 && <Button disabled={loggedUserData?.userLevel?.name === 'Dashboard Only'} variant="outlined" sx={{ fontSize: "13px" }} onClick={() => { editTaskFn(index, 10) }}>
                                    Reopen Task
                                </Button>}
                            </TableCell>}
                        </TableRow>)}
                    </TableBody>
                </Table>
                {task?.length === 0 && <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                    <List sx={{ fontSize: "60px" }} /><br />
                    <Typography variant="body2" sx={{ my: 1 }}>
                        No Tasks found for the data range selected.
                    </Typography>
                </Box>}
            </TableContainer>
        </Box>
    )
}