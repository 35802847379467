import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
// utils
// import axios from '../utils/axios';
import axios from 'axios';
// Camalize and decamelize json 
import humps from 'humps';
import useLocalStorageState from 'use-local-storage-state'
import { isValidToken, setSession, setUserData, setCompanyData } from '../utils/jwt';
// API
import { URL } from "../backend";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    // console.log(action.payload);
    const { data } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      data
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  GET_CITY_NAME: (state, action) => {
    const cityDetails = action.payload;
    console.log(action.payload)
    console.log(cityDetails)

    return {
      ...state,
      isAuthenticated: true,
      cityDetails
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [companyLSData, setCompanyLSData] = useLocalStorageState('companyData');
  const [state, dispatch] = useReducer(reducer, initialState);
  const [headerLogo, setHeaderLogo] = useState();
  const [favIconLogo, setFavIconLogo] = useState();
  const [loginLogo, setLoginLogo] = useState();
  const [profilePicture, setProfilePicture] = useState();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          setSession(accessToken);
          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: null
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {

    // Calling Sign-in API
    const response = await axios.post(`${URL}/login/login`, JSON.stringify({
      email,
      password
    }), {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const { token, data } = humps.camelizeKeys(response.data);

    setSession(token);
    setUserData(data);
    getProfilePicture();
    dispatch({
      type: 'LOGIN',
      payload: {
        isAuthenticated: true,
        data
      }
    });
  };

  const changePassword = async (password) => {

    const accessToken = window.localStorage.getItem('accessToken');

    // Calling reset password API
    const response = await axios.post(`${URL}/login/set_password`, JSON.stringify({
      password,
      token: accessToken
    }), {
      headers: {
        'Content-Type': 'application/json'
      }
    });


    // console.log("response", response.data);
    const { token, data } = humps.camelizeKeys(response.data);

    setSession(token);
    setUserData(data);
    dispatch({
      type: 'LOGIN',
      payload: {
        isAuthenticated: true,
        data
      }
    });
  };

  const changeUserPassword = async (currentPassword, password) => {

    const accessToken = window.localStorage.getItem('accessToken');

    try {
      // Calling reset password API
      const response = await axios.post(`${URL}/staff/reset_password`, JSON.stringify(humps.decamelizeKeys({
        password,
        currentPassword
      })), {
        headers: {
          'Authorization': `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      return response;

    } catch (err) {
      // Handle Error Here
      console.error(err);
      const { data } = humps.camelizeKeys(err.response);
      return data;
    }

  };

  const userslocalisation = async (localisation) => {
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const userId = userData.id;
    // console.log(userId)
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.post(`${URL}/users/localisation`, JSON.stringify(humps.decamelizeKeys({
      localisation
    })), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
  };

  const updateProfilePicture = async (file) => {
    // console.log("file.............", file);

    const accessToken = window.localStorage.getItem('accessToken');

    // Calling reset password API
    const response = await axios.post(`${URL}/users/profile_pic_update`, {
      file
    }, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    getProfilePicture();
  }


  const getProfilePicture = async (scoreCardId) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = await axios.get(`${URL}/users/profile_pic`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {

        // console.log(res);
        const loggedUserData = JSON.parse(window.localStorage.getItem('userData'))

        const results = res.data.data;
        // console.log("picture", results)
        if (!results || results !== null) {
          setProfilePicture(res.data.data);
          setUserData({ ...loggedUserData, profilePicture: results });
        }
        return results;
        // dispatch({
        //     type: 'GET_SINGLE_SCORECARD',
        //     payload: results,
        // })
      })
      .catch((error) => {
        console.error(error)
      })

    return data;
  }

  const deleteProfilePicture = async () => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.delete(`${URL}/users/profile_pic_delete`, {
      headers: {
        'Authorization': `Token ${accessToken}`
      }
    });
    setProfilePicture(undefined);
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'))
    delete loggedUserData?.profilePicture;
    setUserData({ ...loggedUserData });

  }

  const forgotPassword = async (email) => {

    const accessToken = window.localStorage.getItem('accessToken');

    // Calling forgot password API
    const response = await axios.post(`${URL}/login/forgot_password`, JSON.stringify({
      email
    }), {
      headers: {
        'Content-Type': 'application/json'
      }
    });


    // console.log("response", response.data);
    // const { token, data } = humps.camelizeKeys(response.data);

    // setSession(token);
    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     isAuthenticated: true,
    //     data
    //   }
    // });
  };


  // Get company settings
  const getCompanySettings = async () => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = await axios.get(`${URL}/company_settings/1`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {

        // console.log(res);

        const results = humps.camelizeKeys(res.data);
        // setCompanyData(results);
        setCompanyLSData(results);
        return results;
        // dispatch({
        //     type: 'GET_SINGLE_SCORECARD',
        //     payload: results,
        // })
      })
      .catch((error) => {
        console.error(error)
      })

    return data;
  }

  // Update company settings
  // const updateCompanySettings = async (fileAttachments, inbox, supportAccess, autoScore, activityLog, timezone, dateFormat, requestFlow) => {
  //   // console.log(scoreCardId, fieldsName, fieldValue);
  //   const accessToken = window.localStorage.getItem('accessToken');

  //   const response = await axios.patch(`${URL}/company_settings/1`, JSON.stringify(humps.decamelizeKeys({
  //     fileAttachments,
  //     inbox,
  //     supportAccess,
  //     autoScore,
  //     activityLog,
  //     timezone,
  //     dateFormat,
  //     requestFlow
  //   })), {
  //     headers: {
  //       'Authorization': `Token ${accessToken}`,
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // };

  // Update company settings
  const updateCompanySettings = async (obj) => {
    // console.log(scoreCardId, fieldsName, fieldValue);
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.patch(`${URL}/company_settings/1`, JSON.stringify(humps.decamelizeKeys(obj)), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
    const companyData = humps.camelizeKeys(response?.data);
    // console.log(companyData);
    setCompanyLSData(companyData);
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const updateLogo = async (file) => {
    console.log("file.............", file);

    const accessToken = window.localStorage.getItem('accessToken');

    // Calling reset password API
    const response = await axios.post(`${URL}/company_settings/logo_update`, {
      file
    }, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  const deleteHeaderLogo = async () => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.delete(`${URL}/company_settings/logo_delete`, {
      headers: {
        'Authorization': `Token ${accessToken}`
      }
    });

  }

  const updateFavIcoLogo = async (file) => {
    // console.log("file.............", file);

    const accessToken = window.localStorage.getItem('accessToken');

    // Calling reset password API
    const response = await axios.post(`${URL}/company_settings/logo_update`, {
      file,
      type: "favicon"
    }, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  const deleteFavIcoLogo = async () => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.delete(`${URL}/company_settings/logo_delete?type="favicon"`, {
      headers: {
        'Authorization': `Token ${accessToken}`
      }
    });

  }

  const getLoginLogo = async () => {

    const data = await axios.get(`${URL}/users/get_logo`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        const results = res.data;
        // console.log("logo1", res.data.data)
        return results;
      })
      .catch((error) => {
        console.error(error)
      })

    return data;
  }

  // Get country name
  const getCountryName = async (locationName) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = await axios.get(`${URL}/users/list_country?country=${locationName}`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        const results = humps.camelizeKeys(res?.data?.data);
        return results;
      })
      .catch((error) => {
        console.error(error)
      })

    return data;
  }

  // Get city name
  const getCityName = async (countryName, cityName) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = await axios.get(`${URL}/users/list_city?country=${countryName}&city=${cityName}`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        const results = humps.camelizeKeys(res?.data?.data);
        return results;
      })
      .catch((error) => {
        console.error(error)
      })

    return data;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        cityDetails: state.cityDetails,
        method: 'jwt',
        login,
        logout,
        changePassword,
        changeUserPassword,
        forgotPassword,
        userslocalisation,
        updateProfilePicture,
        getProfilePicture,
        deleteProfilePicture,
        getCompanySettings,
        updateCompanySettings,
        updateLogo,
        deleteHeaderLogo,
        headerLogo,
        setHeaderLogo,
        favIconLogo,
        setFavIconLogo,
        updateFavIcoLogo,
        deleteFavIcoLogo,
        getLoginLogo,
        loginLogo,
        setLoginLogo,
        getCountryName,
        getCityName,
        profilePicture,
        setProfilePicture
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
