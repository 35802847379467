import PropTypes from 'prop-types';
// REACT ROUTER IMPORTS
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Box, Button,
    Card, CardHeader,
    Typography,
} from '@material-ui/core';
// COMPONENT IMPORTS
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// ----------------------------------------------------------------------

Label.propTypes = {
    title: PropTypes.string
};

export function Label({ title }) {
    return (
        <Typography variant="overline" component="p" gutterBottom sx={{ color: 'text.secondary' }}>
            {title}
        </Typography>
    );
}

ScoreBlock.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    sx: PropTypes.object,
    isButton: PropTypes.array,
    MBLinks: PropTypes.array
};

export function ScoreBlock({ title, sx, children, isButton, MBLinks }) {
    // console.log(isButton, MBLinks)
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // REACT ROUTER STATE
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();


    return (
        <Card elevation={0} sx={{ boxShadow: 'none', overflow: 'unset', position: 'unset', width: '100%', backgroundColor: 'transparent', py: loggedUserData?.staff ? 2 : 0, px: 1, border: '1px solid transparent' }}>
            <CardHeader sx={{ py: 0, pr: 2, pl: 1, color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} title={<><Box>{title}</Box><HeaderBreadcrumbs sx={{ m: 0, color: '#666666', fontWeight: 600 }} links={MBLinks} /></>} action={<Box sx={{ mt: 3 }}>{searchParams.get("draftType") === "10" ? <Button onClick={() => { navigate("/score/selectDraft?draftType=10,20") }} sx={{ backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', fontWeight: 'normal', borderRadius: '5px', fontSize: '12px', border: '1px solid #dddde7' }} variant="contained">View All Draft</Button> : isButton?.length > 0 && <Button onClick={() => { navigate("/score/selectDraft?draftType=20") }} sx={{ backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', fontWeight: 'normal', borderRadius: '5px', fontSize: '12px', border: '1px solid #dddde7' }} variant="contained" className="halloween-animation">View Draft Result</Button>}</Box>} />
            {/* {loggedUserData?.staff?.staffType === 10 && <CardHeader title={title} sx={{ py: 2, px: 2 }} action={isButton?.length > 0 && <Button onClick={() => { navigate(`/score/selectDraft?gpId=${isButton[0]?.groups?.id}&emp=${isButton[0]?.staff?.id}`) }} sx={{ mr: 1 }} variant="contained">Select Draft</Button>} />} */}

            {/* <Divider sx={{ mx: 3 }} /> */}

            <Box
                sx={{

                    minHeight: 180,

                    ...sx
                }}
            >
                {children}
            </Box>
        </Card>
    );
}
