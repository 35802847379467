import { useEffect, useState, useCallback } from 'react';
// REACT ROUTER IMPORTS
import { useSearchParams, useNavigate } from 'react-router-dom';
// NPM IMPORTS
import _ from 'lodash';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack5';
import moment from 'moment';
// MATERIAL IMPORTS
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    Table,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    TableContainer,
    tableCellClasses,
    TablePagination,
    Typography,
    Tooltip,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { CheckBox, StopCircleOutlined } from "@material-ui/icons";
// COMPONENT IMPORTS
import { MIconButton } from '../../../../../components/@material-extend';
import Scrollbar from '../../../../../components/Scrollbar';
import { PATH_EMP } from "../../../../../routes/paths";
import { myscorelistApi, myscoreSearchlistApi } from '../../../../../_apis_/authApi.js/empapi';
//
import SortingSelectingMyScore from './SortingSelectingMyScore';
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';



// ----------------------------------------------------------------------



const TABLE_HEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'Id'
    },
    {
        id: 'grp',
        numeric: false,
        disablePadding: false,
        label: 'Group'
    },
    {
        id: 'Event Date',
        numeric: false,
        disablePadding: false,
        label: 'Event Date'
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'Category'
    },
    {
        id: 'Evaluator',
        numeric: false,
        disablePadding: false,
        label: 'Evaluator'
    },
    {
        id: 'score',
        numeric: false,
        disablePadding: false,
        label: 'Score'
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status'
    },
    {
        id: 'feedaback',
        numeric: false,
        disablePadding: false,
        label: 'Feedback Date'
    },
    {
        id: 'reviewdby',
        numeric: false,
        disablePadding: false,
        label: 'Reviewed By'
    },
    {
        id: 'Action',
        numeric: false,
        disablePadding: false,
        label: 'Action'
    },

];

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


export default function ListScore() {
    const mbLink = [];
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REACT ROUTER STATES
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    // REACT STATES
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [tableOrder, setTableOrder] = useState(false);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);

    useEffect(() => {
        // dispatch(getScoreList(searchParams.get("empId"), 1))
        (async function () {
            try {
                setData([]);
                setIsLoading(true);
                const response = await myscorelistApi(searchParams.get("date1"), searchParams.get("date2"), searchParams.get("range"), searchParams.get("date_number"), searchParams.get("staff"), searchParams.get("scorecard"));
                console.log(response?.data?.results)
                setData(response?.data?.results)
                setCount(response?.data?.count)
                setIsLoading(false);

            } catch (err) {
                if (err) {
                    setIsLoading(false);
                }
            }
        })();
    }, [])


    const getMyscoreData = async (pg) => {
        const response = await myscorelistApi(searchParams.get("date1"), searchParams.get("date2"), searchParams.get("range"), searchParams.get("date_number"), searchParams.get("staff"), searchParams.get("scorecard"), pg);
        console.log(response?.data?.results)
        setIsLoading(false);
        setData(response?.data?.results)

    }

    const searchMyScoreData = async (pg, value) => {
        setData([]);
        setIsLoading(true);

        const response = await myscoreSearchlistApi(searchParams.get("date1"), searchParams.get("date2"), searchParams.get("range"), searchParams.get("date_number"), searchParams.get("staff"), searchParams.get("scorecard"), value, pg);
        console.log(response)
        setIsLoading(false);
        setCount(response?.data?.count)
        setData(response?.data?.results)
    }


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setData([]);
        setIsLoading(true);
        if (filterName && filterName !== "") {
            searchMyScoreData(newPage + 1);
            return setPage(newPage);
        }
        getMyscoreData(newPage + 1);

        return setPage(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleTableOrder = () => {
        setData(data?.reverse());
        setTableOrder(!tableOrder);
    }


    const sendQuery = (queryText) => {
        if (queryText?.length === 0) {
            return getMyscoreData();
        }
        if (queryText?.length > 0) {
            return searchMyScoreData(1, queryText);
        }
    }

    const delayedQuery = useCallback(_.debounce((query) => sendQuery(query), 1000), []);

    const handleFilterByName = (event) => {
        setData([]);
        setIsLoading(true);
        setPage(0);
        setFilterName(event.target.value)
        delayedQuery((event.target.value)?.trim());

    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <>

            <ScoreWrapper title="My Score" link={[{ name: 'Home', href: PATH_EMP?.dashboard }, { name: 'Back', href: -1 }, { name: 'My Scores' }]}>
                <Stack sx={{ width: "100%", px: 1, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                    <SearchStyle
                        value={filterName}
                        onChange={handleFilterByName}
                        placeholder="Search Score..."
                        startAdornment={
                            <>
                                <InputAdornment position="start">
                                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            </>
                        }
                    />
                    <Stack direction="row" alignItems="center" alignContent="center">
                        <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                        <TablePagination
                            sx={{ border: "1px solid transparent" }}
                            rowsPerPageOptions={100}
                            component="div"
                            count={count || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack>
                </Stack>
                {/* <Divider /> */}
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "800px", py: 2 }}>
                        <Table size='small'>
                            <TableHead>
                                {TABLE_HEAD?.map(obj => <TableCell align='center'>
                                    {obj?.label}
                                </TableCell>)}
                            </TableHead>
                            <TableBody>
                                {data?.map((row, index) => {


                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{
                                                [`& .${tableCellClasses.root}`]: {
                                                    borderBottom: (theme) => `1px solid ${(index !== data?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                                },
                                            }}
                                        >
                                            <TableCell scope="row" align="center" >
                                                <Box sx={{ width: "100%" }}>
                                                    {row?.id}
                                                </Box>
                                            </TableCell>
                                            <TableCell align='center' scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Button sx={{ padding: '0px 8px', mx: 1, fontSize: "12px", fontWeight: '400', marginRight: '5px', marginBottom: '2px' }} variant="outlined" color="inherit">{row?.groups}</Button>
                                                </Box>

                                            </TableCell>

                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Typography sx={{ fontSize: "13px" }}>{row?.event_date}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Button sx={{ padding: '0px 8px', mx: 1, fontSize: "12px", fontWeight: '400', marginRight: '5px', marginBottom: '2px' }} variant="outlined" color="inherit">{row?.category}</Button>

                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Button sx={{ padding: '0px 8px', mx: 1, fontSize: "12px", fontWeight: '400', marginRight: '5px', marginBottom: '2px' }} variant="outlined" color="inherit">{row?.evaluator}</Button>

                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">

                                                    {row?.score_result === 20 && <Button variant="contained" sx={{ padding: '0px 8px', mx: 1, fontSize: "12px", fontWeight: '400', marginRight: '5px', marginBottom: '2px', color: "white", backgroundColor: "#55efc4" }} color="inherit">{row?.percentage}%</Button>}
                                                    {row?.score_result === 10 && <Button variant="contained" sx={{ padding: '0px 8px', mx: 1, fontSize: "12px", fontWeight: '400', marginRight: '5px', marginBottom: '2px', color: "white", backgroundColor: "#e74c3c" }} color="inherit">{row?.percentage}%</Button>}
                                                    {row?.score_result === 30 && <Button variant="contained" sx={{ padding: '0px 8px', mx: 1, fontSize: "12px", fontWeight: '400', marginRight: '5px', marginBottom: '2px', color: "white", backgroundColor: "#feca57" }} color="inherit">{row?.percentage}%</Button>}

                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">
                                                    {row?.is_score_reviewed && <CheckBox />}
                                                    {!row?.is_score_reviewed && <StopCircleOutlined />}
                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">
                                                    {row?.is_score_reviewed && <Typography sx={{ fontSize: "14px" }}>{moment(row?.score_reviewed_on).format("LL")}</Typography>}
                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">
                                                    <Typography sx={{ fontSize: "14px" }}>{row?.score_reviewed_by}</Typography>
                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">

                                                    <Button sx={{ mx: 1, backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: '14px', border: '1px solid #dddde7' }} variant="contained" onClick={() => { navigate(searchParams.get("stff_emp_id") ? `/reports/reviewPerformance?score_card_Id=${row?.score_card}&score_Id=${row?.id}&review=True&sup_view=True` : `/reports/reviewPerformance?score_card_Id=${row?.score_card}&score_Id=${row?.id}&review=True`) }} >View</Button>
                                                </Box>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {data?.length === 0 && (
                                    <TableRow>



                                        {isLoading ? <TableCell colSpan={12}>
                                            <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                                <CircularProgress /> Loading Scores...
                                            </Box>
                                        </TableCell> : <TableCell colSpan={12}>
                                            <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>
                                                No Scores Found
                                            </Typography>
                                        </TableCell>}

                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Scrollbar>

                <Divider />
                <Box
                    sx={{
                        px: 3,
                        py: 1.5,
                        top: 0,
                    }}
                />

            </ScoreWrapper>
        </>
    );
}
