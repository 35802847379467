import React from 'react';
// REACT ROUTER IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
// NPM IMPORTS
import moment from 'moment';
import { Interweave } from 'interweave';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
// import { Stack, Box, Grid, Container, Paper, ToggleButton, ToggleButtonGroup, Typography, Tab, Tabs, Card, Button, TextField, FormLabel, FormGroup, Divider } from '@material-ui/core';
// MATERIAL IMPORTS
import {
    Box,
    Divider,
    Paper,
    Stack,
    ToggleButton, ToggleButtonGroup, Typography, Tab,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { MilitaryTech } from '@material-ui/icons';
// API IMPORTS
import { getTeamGoalApi, getGroupGoalApi } from '../../../_apis_/authApi.js/staffApi';
import { prsnlGoalApi, empGoalApi } from '../../../_apis_/authApi.js/empapi';
// CONTEXT IMPORTS
import { EmpContext } from '../../../contexts/employeecontext';






const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        padding: "0 0 5px 0",
        width: "100%",
        // backgroundColor: "red",
        margin: "5px 0 10px 0",
        "&::-webkit-scrollbar": {
            marginTop: "10px",
            width: 15,
            height: 4,
            cursor: "pointer",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
            outline: `1px solid slategrey`,
            borderRadius: 4,
            cursor: "pointer",
        },
    },

}));


EmpGoalSummary.propTypes = {
    theme: PropTypes.string,
    themeColor: PropTypes.object
}



export default function EmpGoalSummary({ theme, themeColor }) {
    const user = JSON.parse(window.localStorage.getItem('userData'))
    const [searchParams, setSearchParams] = useSearchParams();
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const classes = useStyles();
    const { datesData, IdEmp } = React.useContext(EmpContext);
    const [value, setValue] = React.useState('1');
    const [btnValue, setBtnValue] = React.useState(0);
    const [goalArray, setGoalArray] = React.useState([]);
    const [label, setLabel] = React.useState("Personal Goals");
    const [grpIndex, setGrpIndex] = React.useState(0);
    const [teamIndex, setTeamIndex] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleData = (index) => {
        setBtnValue(index);

    }

    const tabButtons = (propsBtn) => {
        console.log(propsBtn.direction, propsBtn)
        if (propsBtn.direction === 'left' && !propsBtn.disabled) {
            return (<Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                <Icon onClick={() => propsBtn.onClick()} icon="icon-park-solid:left-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />
            </Stack>)
        }
        if (propsBtn.direction === 'right' && !propsBtn.disabled) {
            return (
                <Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                    <Icon onClick={() => propsBtn.onClick()} icon="icon-park-solid:right-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />

                </Stack>
            )
        }
        return null

    }

    const SIMPLE_TAB = []

    if (user?.staff?.isPersonalGoals && !searchParams.get("stff_emp_id")) {
        SIMPLE_TAB.push({ value: '1', label: 'Personal Goals', disabled: false },
            { value: '2', label: 'Employee Goals', disabled: false },
            { value: '3', label: 'Team Goals', disabled: true },
            { value: '4', label: 'Group Goals', disabled: true })
    }
    else {
        SIMPLE_TAB.push(
            { value: '1', label: 'Employee Goals', disabled: false },
            { value: '2', label: 'Team Goals', disabled: true },
            { value: '3', label: 'Group Goals', disabled: true })
    }


    React.useEffect(() => {
        const user = JSON.parse(window.localStorage.getItem('userData'))
        async function getGoals() {
            if (label === "Personal Goals" && user?.staff?.staffType === 10) {
                const { data } = await prsnlGoalApi(IdEmp?.user?.id || user?.id);
                return setGoalArray(data.results)
                // return console.log(data.results)
            }
            if (label === "Employee Goals") {
                const { data } = await empGoalApi(IdEmp?.id || user.staff?.id);
                return setGoalArray(data.results)
                // return console.log(data)
            }
            if (label === "Team Goals") {
                const { data } = await getTeamGoalApi(teamIndex || (IdEmp?.user?.teams[0]?.id || user?.teams[0]?.id));
                console.log(teamIndex)

                return setGoalArray(data.results)
            }
            if (label === "Group Goals") {
                const { data } = await getGroupGoalApi(grpIndex || (IdEmp?.user?.groups[0]?.id || user?.groups[0]?.id));
                return setGoalArray(data.results)
                // return console.log(data)
            }
        }
        if (datesData?.date1) {
            getGoals()
        }
    }, [datesData, label, grpIndex, teamIndex, IdEmp])


    return (

        <>

            <Box sx={{ px: 5, py: 2, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }} display="flex" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: theme => theme.palette.text.primary, fontSize: '16px' }}>Goals</Typography>

                {loggedUserData?.staff?.staffType === 10 && <Link to="/employee/goals" style={{ textDecoration: "none", color: theme === 'light' ? themeColor?.main : themeColor?.dark }}>

                    View Goals

                </Link>}
                {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={`/employee/goals?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=False`} style={{ textDecoration: "none", color: theme === 'light' ? themeColor?.main : themeColor?.dark }}>

                    View Goals

                </Link>}
                {!loggedUserData?.staff && <Link to={`/employee/goals?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=True`} style={{ textDecoration: "none", color: theme === 'light' ? themeColor?.main : themeColor?.dark }}>

                    View Goals

                </Link>}
            </Box>
            <Divider />
            <Box sx={{ px: 3, py: 2 }}>
                <TabContext value={value}>

                    <TabList TabIndicatorProps={{ style: { backgroundColor: theme === 'light' ? themeColor?.main : themeColor?.dark } }} ScrollButtonComponent={(props) => tabButtons(props)} variant="scrollable" onChange={handleChange}>
                        {SIMPLE_TAB.map((tab, index) => (
                            <Tab sx={{ fontWeight: 200, "&.Mui-selected": { color: (theme) => theme.palette.text.buttonText, fontWeight: 700 } }} key={tab.value} label={tab.label} onClick={() => { setLabel(tab.label); setBtnValue(0); setTeamIndex(null) }} value={String(index + 1)} />
                        ))}
                    </TabList>


                    {label === "Group Goals" && <Box className={classes.root}>
                        <ToggleButtonGroup
                            size="small"
                            value={btnValue}
                            sx={{ ml: 4 }}
                        >
                            {!IdEmp && user?.groups?.map((row, index) =>
                                <ToggleButton key={index} sx={{ color: "black" }} value={index} onClick={() => { setGrpIndex(row?.id); handleData(index) }} aria-label="left aligned">
                                    {row.name}
                                </ToggleButton>
                            )}
                            {IdEmp && IdEmp?.user?.groups?.map((row, index) =>
                                <ToggleButton key={index} sx={{ color: "black" }} value={index} onClick={() => { setGrpIndex(row?.id); handleData(index) }} aria-label="left aligned">
                                    {row.name}
                                </ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </Box>}
                    {label === "Team Goals" && <Box className={classes.root}>
                        <ToggleButtonGroup
                            size="small"
                            value={btnValue}
                            sx={{ ml: 4 }}
                        >
                            {!IdEmp && user?.teams?.map((row, index) =>
                                <ToggleButton sx={{ color: "black" }} key={index} value={index} onClick={() => { setTeamIndex(row?.id); handleData(index) }} aria-label="left aligned">
                                    {row.name}
                                </ToggleButton>
                            )}
                            {IdEmp && IdEmp?.user?.teams?.map((row, index) =>
                                <ToggleButton sx={{ color: "black" }} key={index} value={index} onClick={() => { setTeamIndex(row?.id); handleData(index) }} aria-label="left aligned">
                                    {row.name}
                                </ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </Box>}
                    <Box
                        sx={{
                            py: 1,
                            mt: 1,
                            height: 'auto',
                            width: '100%',
                            borderRadius: 1
                        }}
                    >
                        {SIMPLE_TAB.map((panel, index) => (
                            <TabPanel key={panel.value} value={String(index + 1)}>
                                {goalArray?.length > 0 && goalArray?.map((row, index) => <Box key={index} sx={{ p: 2, width: "95%", margin: "0 auto" }}>
                                    <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between">
                                        <Typography sx={{ fontSize: "12px", my: 1, ml: 2 }}>
                                            {`${panel.label} #${index + 1}`}
                                        </Typography>
                                        <Typography sx={{ fontSize: "12px", my: 1, ml: 2 }}>
                                            {`${moment(row?.created_at).format("LL, LT")}`}
                                        </Typography>

                                    </Stack>

                                    <Box sx={{ p: 2, my: 1, fontSize: "14px", minHeight: "60px", border: "2px dotted #dcdde1", backgroundColor: theme === 'light' ? "#dff9fb" : '#636e72', borderRadius: 1, width: "100%", margin: "0 auto" }}>
                                        <Interweave content={row?.description} />                                        </Box>

                                </Box>)}
                                {
                                    goalArray?.length === 0 && <Box sx={{ width: "90%", minHeight: "150px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                        <MilitaryTech sx={{ fontSize: "60px" }} /><br />
                                        <Typography variant="body2" sx={{ my: 1 }}>
                                            No Goals Found.
                                        </Typography>
                                    </Box>
                                }
                            </TabPanel>
                        ))}
                    </Box>
                </TabContext>
            </Box>
        </>
    )
}