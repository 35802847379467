import { useState, useContext, useEffect, useCallback } from 'react';
// REDUX IMPORTS
import { useDispatch } from 'react-redux';
// MATERIAL IMPORTS
import {
  Autocomplete, Avatar,
  Box, Button,
  CircularProgress, Checkbox,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControlLabel, FormControl,
  InputAdornment, IconButton, InputLabel,
  MenuItem,
  Stack, Skeleton,
  TextField, Typography,
  List, ListItem, ListItemText
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
// FORMIK IMPORTS
import { useFormik } from 'formik';
import * as Yup from 'yup';
// NPM IMPORTS
import _ from "lodash";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import { Visibility, VisibilityOff, Close, PersonAddAlt } from '@material-ui/icons';
// COMPONENT IMPORTS
import { MIconButton } from '../../../../../../components/@material-extend';
// API IMPORTS
import { addStaffApi, editStaffApi, singleStaffApi, listTeamApi, filteredlistSupervisorApi, getStaffCityNameApi, getStaffCountryNameApi } from '../../../../../../_apis_/authApi.js/staffApi';
import { editEmp, newEmp, delEmp, newSup, getAllSup } from '../../../../../../redux/action/staffAction';
// CONTEXT IMPORTS
import { StaffContext } from '../../../../../../contexts/staffContext';
import { SettingsContext } from '../../../../../../contexts/SettingsContext';
// 
import DisableWrapper from '../../../../../dashboard/DisableWrapper';


export default function EmpDialog({ showButton, setOpen, setCollapseId, setEmpList, apiSupId, handleTabChange }) {
  // REDUX STATES
  const dispatch = useDispatch();
  // THEME STATES
  const theme = useTheme();
  // CONTEXT STATES
  const { allTeam, allGroup, setT11, getEmployee, getSupervisor, empForm, setEmpForm, employeeID, setEmployeeID, emp, setEmp } = useContext(StaffContext);
  const { setColor } = useContext(SettingsContext);
  // REACT STATES
  const [formGroup, setFormGroup] = useState([]);
  const [formTeam, setFormTeam] = useState([]);
  const [teamErr, setTeamErr] = useState("none");
  const [grpErr, setGrpErr] = useState("none");
  const [errExist, setErrExist] = useState("none");
  const [errUserNameExist, setErrUserNameExist] = useState("none");
  const [isVisible, setVisible] = useState(false);
  const [disBtn, setDisBtn] = useState(false);
  const [disField, setDisField] = useState(false);
  const [supervisorList, setSupervisorList] = useState([]);
  const [teamOption, setTeamOption] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cityDetailsData, setCityDetailsData] = useState([]);
  const [countryDetailsData, setCountryDetailsData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputCountryValue, setInputCountryValue] = useState("");
  const [locationCountryValue, setLocationCountryValue] = useState("");
  const [locationCityValue, setLocationCityValue] = useState("");

  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const aiEnabled = loggedUserData?.aiEnabled

  // SNACKBAR STATES
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const checkNameValidation = (value) => {
    if (/^[\s0-9]*$/.test(value)) {
      return '*Field with only numbers are not allowed .'
    }
    if (/^[\s0-9][\sa-zA-Z0-9'-]*$/.test(value)) {
      return '*Field should start with [a-z]'
    }

    return '*Field should not have special characters [!@#$%^&*()]'

  }

  const checkPasswordValidation = (value) => {
    return '*No Spaces are allowed .'
  }

  // FORMIK STATES
  const formik = useFormik({
    initialValues: { email: '', fname: '', lname: '', group: '', password: '', team: '', notes: '', extID: "", emp_type: "", sup: null, staff_type: '10', agentAccess: false, slfScore: false, prsnlGoals: false, location1: null, location2: null, short_username: null, is_ai_access: false },
    validationSchema: Yup.object({
      email: Yup.string().email('invalid email').test((value, { createError }) => {
        if (/[#$%^&*(){}|?/><=+]/.test(value) === false) {
          return true
        }
        return createError({ message: 'invalid email(*no special characters allowed)' })
      }),
      fname: Yup.string().required('*Required').test((value, { createError }) => {

        if (/^[a-zA-Z][\sa-z0-9A-Z'-]*$/.test(value) === false) {
          return createError({ message: checkNameValidation(value) })
        }
        return true
      }),
      lname: Yup.string().required('*Required').test((value, { createError }) => {
        if (/^[a-zA-Z][\sa-z0-9A-Z'-]*$/.test(value) === false) {
          return createError({ message: checkNameValidation(value) })
        }
        return true
      }),
      password: Yup.string().test((value, { createError }) => {
        if (/^[^\s]+$/.test(value) === false) {
          return createError({ message: checkPasswordValidation(value) })
        }
        return true
      }).min(8, "Password should be atleast 8 charaters"),
      sup: Yup.string().nullable(),
      location1: Yup.string().required('*Required').nullable(),
      location2: Yup.string().required('*Required').nullable(),
      staff_type: Yup.string().required('*Required'),
      extID: Yup.string().min(4, `External Id should have atleast length of 4`).max(8, `External Id length should not exceed more than 8`).matches(/^[0-9a-zA-Z]{4,8}$/, 'Only AlphaNumeric allowed').nullable()
    }),
    onSubmit: values => {
      if (formGroup?.length === 0) {
        return setGrpErr("block");
      }
      if (formTeam?.length === 0) {
        return setTeamErr("block");
      }

      // console.log("ok")

      // console.log(formGroup);
      // console.log(formTeam);

      async function edit() {
        setDisBtn(true)
        try {
          const newObj = {
            user: {
              first_name: values.fname,
              last_name: values.lname,
              username: values.email.toLowerCase(),
              email: values.email.toLowerCase(),
              short_username: values.short_username === null ? '' : values.short_username?.trim().toLowerCase(),
              password: values.password || null,
              city: values.location2 === '' ? null : values.location2,
              country: values.location1 === '' ? null : values.location1,
              is_ai_access: values.is_ai_access
            },
            notes: values.notes,
            groups: formGroup,
            teams: formTeam,
            employment_type: values.emp_type,
            staff_type: values.staff_type,
            external_id: values.extID === '' ? null : values?.extID,
            is_agent_access: values.agentAccess,
            is_personal_goals: values.prsnlGoals,
            is_self_score: values.slfScore,
            supervisor: values.sup,

          }

          const res = await editStaffApi(newObj, employeeID);
          if (showButton) {
            handleTabChange(apiSupId);
          }
          // console.log(newObj)
          // console.log(res.data)
          if (res?.data?.staff_type === 20) {
            dispatch(delEmp((res?.data?.id)));
            dispatch(newSup(res?.data))
          }
          else {
            dispatch(editEmp(res.data));
            dispatch(getAllSup(1))
          }

          setDisBtn(false)
          enqueueSnackbar('Updated Employee Successfully', {
            variant: 'success',
            action: (key) => (

              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          // console.log(res.data);

          handleClose();


        }
        catch (err) {
          if (!navigator.onLine) {
            enqueueSnackbar('No Internet Connection Found', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
            return setDisBtn(false);
          }
          if (err?.response?.data?.message?.includes("short_username") || err?.response?.data?.message?.includes("Short username")) {
            setErrUserNameExist("block");
            setDisBtn(false);
            return enqueueSnackbar('User Name Already Exists', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }

          if (err?.response?.data?.message?.includes("already exists")) {
            setErrExist("block");
            setDisBtn(false);
            return enqueueSnackbar('Staff Already exists', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }
          setDisBtn(false);
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });


        }

      }

      if (employeeID) return edit()


      async function newStaff() {
        setDisBtn(true)
        try {
          const newObj = {
            user: {
              first_name: values.fname,
              last_name: values.lname,
              username: values.email.toLowerCase(),
              email: values.email.toLowerCase(),
              short_username: values.short_username === null ? '' : values.short_username?.trim().toLowerCase(),
              city: values.location2 === '' ? null : values.location2,
              country: values.location1 === '' ? null : values.location1,
              is_ai_access: values.is_ai_access
            },
            notes: values.notes,
            groups: formGroup,
            teams: formTeam,
            employment_type: values.emp_type || 10,
            staff_type: values.staff_type,
            external_id: values.extID === '' ? null : values?.extID,
            is_agent_access: values.agentAccess,
            is_personal_goals: values.prsnlGoals,
            is_self_score: values.slfScore,
            supervisor: values.sup,


          }

          const res = await addStaffApi(newObj);
          console.log(res);
          dispatch(newEmp(res.data.data))
          setDisBtn(false)
          enqueueSnackbar('Added Employee Successfully', {
            variant: 'success',
            action: (key) => (

              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          console.log(res.data.data);
          handleClose();


        } catch (err) {
          console.log("xyz", err?.response?.data?.message);
          if (!navigator.onLine) {
            enqueueSnackbar('No Internet Connection Found', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
            return setDisBtn(false);
          }
          if (err?.response?.data?.message?.includes("short_username") || err?.response?.data?.message?.includes("Short username")) {
            setErrUserNameExist("block");
            setDisBtn(false);
            return enqueueSnackbar('User Name Already Exists', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }

          if (err?.response?.data?.message?.includes("already exists")) {
            setErrExist("block");
            setDisBtn(false);
            return enqueueSnackbar('Staff Already exists', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }

          setDisBtn(false);
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });

        }
      }

      newStaff()


    }
  })



  // find location function
  const handleFindLocationCountry = async (string) => {
    if (string.length > 0 && string !== formik?.values?.location1) {
      const { data } = await getStaffCountryNameApi(string);
      // console.log(data?.data);
      setCountryDetailsData(data?.data);
    }
  }
  const handleFindLocationCity = async (country, cityQuery) => {
    if (cityQuery.length > 0 && cityQuery !== formik?.values?.location2) {
      const { data } = await getStaffCityNameApi(country, cityQuery);
      setCityDetailsData(data?.data);
    }

    if (cityQuery.length < 1) {
      setCityDetailsData([]);
    }
  }

  const delayedQuery = useCallback(_.debounce((query1, query2) => handleFindLocationCity(query1, query2), 500), []);

  useEffect(() => {


    const newArr = []
    getSupervisor?.map(obj => { if (obj?.user?.teams?.some(objs => formTeam?.includes(objs?.id))) { return newArr.push(obj) } return null })
    // setSupervisorList(newArr);
    if (empForm) getSupervisorList();

  }, [formGroup, formTeam])


  const getSupervisorList = async () => {
    const { data } = await filteredlistSupervisorApi([...formGroup, 0], [...formTeam, 0]);
    const arr = data?.data?.filter((v, i, a) => a.findIndex(v2 => ['id'].every(k => v2[k] === v[k])) === i)
    setSupervisorList(arr);
  }


  const setEmpDetails = async (emp) => {
    if (emp?.user?.username) {
      setDisField(true)
    }
    if (emp?.user?.first_name) {
      formik.setFieldValue('agntAccss', emp?.is_agent_access)
      formik.setFieldValue('email', emp?.user?.email)
      formik.setFieldValue('fname', emp?.user?.first_name)
      formik.setFieldValue('lname', emp?.user?.last_name)
      formik.setFieldValue('short_username', emp?.user?.short_username)
      // formik.setFieldValue('password', emp?.user?.password)
      formik.setFieldValue('extID', emp?.external_id)
      setFormGroup(emp?.user?.groups.map(obj => obj.id))
      setFormTeam(emp?.user?.teams.map(obj => obj.id))
      formik.setFieldValue('emp_type', emp?.employment_type)
      formik.setFieldValue('sup', emp?.supervisor?.id || "")
      formik.setFieldValue('notes', emp?.notes || "")
      formik.setFieldValue('agentAccess', emp?.is_agent_access)
      formik.setFieldValue('slfScore', emp?.is_self_score);
      formik.setFieldValue('prsnlGoals', emp?.is_personal_goals);
      formik.setFieldValue('staff_type', emp?.staff_type);
      formik.setFieldValue('short_username', emp?.user?.short_username);
      formik.setFieldValue('is_ai_access', emp?.user?.is_ai_access);
      setTeamList(emp?.user?.groups.map(obj => obj.id));
    }

    if (emp?.user?.country) {
      const { data } = await getStaffCountryNameApi(emp?.user?.country);
      setCountryDetailsData(data?.data);
      setLocationCountryValue(data?.data?.find((option) => option?.country_name === emp?.user?.country))
      formik.setFieldValue("location1", emp?.user?.country)
    }

    if (emp?.user?.city && emp?.user?.country) {
      const { data } = await getStaffCityNameApi(emp?.user?.country, emp?.user?.city);
      setCityDetailsData(data?.data);
      setLocationCityValue(data?.data?.find((option) => option?.city_name === emp?.user?.city))
      formik.setFieldValue("location2", emp?.user?.city)
    }
  }

  // useffect 
  useEffect(async () => {
    setVisible(false);
    setIsSubmitted(false);
    setGrpErr("none");
    setTeamErr("none")
    if (employeeID) {
      const getEmpDetails = async () => {


        if (showButton) {
          const { data } = await singleStaffApi(employeeID);
          setEmpDetails(data);
          return setEmp(data);

        }

        return getEmployee?.find((obj) => {
          if (obj?.id === employeeID) {
            setEmpDetails(obj);
            setEmp(obj);

          }
          return null
        })
      }
      getEmpDetails();


    }

  }, [empForm])

  useEffect(() => {
    if (_.isEmpty(formik.errors) === false && isSubmitted === true) {
      if (formGroup?.length === 0) {
        setGrpErr("block");
      }
      if (formTeam?.length === 0) {
        setTeamErr("block");
      }
    }
  }, [formik.errors, isSubmitted])

  useEffect(() => {
    if (formGroup?.length === 0 && isSubmitted === true) {
      setGrpErr("block");
    }
    if (formTeam?.length === 0 && isSubmitted === true) {
      setTeamErr("block");
    }
    if (formGroup?.length > 0 && isSubmitted === true) {
      setGrpErr("none");
    }
    if (formTeam?.length > 0 && isSubmitted === true) {
      setTeamErr("none");
    }
  }, [formGroup, formTeam])


  // console.log(formGroup); console.group(formTeam)




  // FUNCTIONS

  async function setTeamList(id) {
    const { data } = await listTeamApi(id);

    setTeamOption(data?.data);
  }

  // close dialog
  const handleClose = () => {

    setEmp("");
    setEmployeeID("");
    formik.resetForm();
    setFormGroup([]); setFormTeam([]);
    setSupervisorList([]);
    setGrpErr("none"); setTeamErr("none");
    setEmpForm(false);
    setErrExist("none");
    setErrUserNameExist("none")
    setDisField(false);
    setTeamOption([]);
    setCityDetailsData([])
    setCountryDetailsData([])
    setLocationCountryValue("")
    setLocationCityValue("")
    setInputCountryValue("")
    if (showButton) {
      setOpen(false); setCollapseId(undefined); setEmpList([])
    }

  };

  // Multiple select
  const handleAddGroups = async (value) => {
    if (formGroup?.includes(value)) {

      const arr3 = formGroup?.filter((val) => { if (val !== value) { return val } return null })
      setFormGroup([...arr3])
      const { data } = await listTeamApi([...arr3, 0]);
      console.log(data?.data);
      setTeamOption(data?.data);
      const arr = []
      allTeam?.map(obj => { if (obj.groups.id === value) { return arr.push(obj.id) } return null })
      const arr1 = [];
      console.log(arr)

      console.log(arr1)
      if (arr3?.length === 0) {
        return setFormTeam([])
      }
      formTeam.map(val => {
        if (arr.includes(val)) {
          return null
        }
        return arr1.push(val)
      })
      setFormTeam(arr1)
    }
    else {
      setFormGroup([...formGroup, value])
      setT11([])
      const { data } = await listTeamApi([...formGroup, value]);
      setTeamOption(data?.data);
      console.log(data?.data);

    }
  };

  const handleAddTeams = (value) => {
    if (formTeam?.includes(value)) {
      const arr = formTeam?.filter((val) => { if (val !== value) { return val } return null })
      setFormTeam([...arr])
    }
    else {
      setFormTeam([...formTeam, value])
    }
  };



  return (
    <div>
      {!showButton && (
        <Button className="halloween-animation"
          variant="contained"
          startIcon={
            <PersonAddAlt
              sx={{ color: '#636e72', stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px' }}
            />
          }
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' },
            color: '#fb5d2e',
            fontWeight: 'normal',
            borderRadius: '5px',
            fontSize: '12px',
            border: '1px solid #dddde7'
          }}
          onClick={() => {
            setEmpForm(true);
          }}
        >
          Add Employee
        </Button>
      )}

      <Dialog open={empForm} maxWidth='sm' fullWidth >
        <DisableWrapper exclude={['exclude-field', 'no-disable']}>
          <form autoComplete='off'>
            <Stack
              sx={{ py: 1, pr: 1, borderBottom: (theme) => `2px solid ${theme.palette.text.primaryBolderBorder}` }}
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
            >
              <DialogTitle>{employeeID ? 'Update Employee' : 'Add New Employee'} </DialogTitle>
              <IconButton
                sx={{ mt: 1 }}
                onClick={() => {
                  formik.resetForm();
                  setFormTeam('');
                  setFormGroup('');
                  handleClose();
                }}
                className="no-disable"
              >
                <Close />
              </IconButton>
            </Stack>
            {/* <Divider /> */}
            <DialogContent sx={{ pt: 3 }}>
              <Stack spacing={3}>
                {employeeID && <Stack alignItems="center" direction="row" sx={{ width: '100%' }} >
                  <Stack
                    direction={{ xs: 'column', sm: 'column' }}
                    sx={{ width: '50%' }}
                    spacing={2}
                  >
                    <Box>

                      <TextField
                        fullWidth
                        label={
                          <>
                            First Name
                            <Typography variant="body" sx={{ color: setColor?.main, fontWeight: 'bold' }}>
                              &nbsp;*
                            </Typography>
                          </>
                        }
                        name="fname"
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off'
                          }
                        }}
                        {...formik.getFieldProps('fname')}

                        onChange={(event) => {
                          formik.setFieldValue('fname', event.target.value);
                        }}
                      />



                      {formik.errors.fname && formik.touched.fname ? (
                        <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.fname}</Typography>
                      ) : null}
                    </Box>
                    <Box>

                      <TextField
                        fullWidth
                        label={
                          <>
                            Last Name
                            <Typography variant="body" sx={{ color: setColor?.main, fontWeight: 'bold' }}>
                              &nbsp;*
                            </Typography>
                          </>
                        }
                        name="lname"
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off'
                          }
                        }}
                        {...formik.getFieldProps('lname')}
                      />



                      {formik.errors.lname && formik.touched.lname ? (
                        <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.lname}</Typography>
                      ) : null}
                    </Box>
                  </Stack>
                  <Stack
                    justifyContent="center"
                    direction="row"
                    sx={{ width: '50%', display: employeeID ? 'flex' : 'none' }}
                  >
                    <Avatar sx={{ width: 130, height: 130, border: '8px solid #ff7675' }} />
                  </Stack>
                </Stack>}

                {!employeeID && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Box sx={{ width: '50%' }}>

                    <TextField
                      fullWidth
                      required
                      type="text"
                      label="First Name"
                      name="fname"
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off'
                        }
                      }}
                      {...formik.getFieldProps('fname')}
                      autoComplete="chrome-off"
                    // inputProps={{
                    //     autoComplete: 'new-password'
                    // }}

                    />



                    {formik.errors.fname && formik.touched.fname ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.fname}</Typography>
                    ) : null}
                  </Box>


                  <Box sx={{ width: '50%' }}>

                    <TextField
                      fullWidth
                      required
                      label="Last Name"
                      name="lname"
                      type='text'
                      sx={{
                        'input::-ms-clear': {
                          display: 'none'
                        },
                        'input::-ms-reveal': {
                          display: 'none'
                        }
                      }}
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off'
                        }
                      }}
                      {...formik.getFieldProps('lname')}

                    />


                    {formik.errors.lname && formik.touched.lname ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.lname}</Typography>
                    ) : null}
                  </Box>
                </Stack>}

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Box sx={{ width: employeeID ? '50%' : '100%' }}>
                    {(!employeeID || (employeeID && emp?.user?.first_name)) && (
                      <TextField
                        fullWidth
                        // required
                        type="email"
                        label="Email"
                        name="email"
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off'
                          }
                        }}
                        {...formik.getFieldProps('email')}
                        autoComplete="chrome-off"
                      // inputProps={{
                      //     autoComplete: 'new-password'
                      // }}

                      />
                    )}
                    {employeeID && showButton && !emp?.user?.first_name && (
                      <Skeleton variant="text" sx={{ height: '65px' }} />
                    )}

                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', display: errExist }}>
                      User Already Exists
                    </Typography>

                    {formik.errors.email && formik.touched.email ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.email}</Typography>
                    ) : null}
                  </Box>

                  <Box sx={{ width: employeeID ? '50%' : '100%' }}>
                    <TextField
                      fullWidth
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off'
                        }
                      }}
                      label="User name"
                      value={formik.values.short_username}
                      {...formik.getFieldProps('short_username')}
                      autoComplete="chrome-off"
                    //   error={Boolean(touched.short_username && errors.short_username)}
                    //   helperText={touched.short_username && errors.short_username}
                    />
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', display: errUserNameExist }}>
                      User Name Already Exists
                    </Typography>

                    {formik.errors.short_username && formik.touched.short_username ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.short_username}</Typography>
                    ) : null}
                  </Box>
                  <Box sx={{ width: '50%', display: employeeID ? 'block' : 'none' }}>
                    {(!employeeID || (employeeID && emp?.user?.first_name)) && (
                      <TextField
                        fullWidth
                        hidden
                        label="Password"
                        name="password"
                        sx={{
                          'input::-ms-clear': {
                            display: 'none'
                          },

                          'input::-ms-reveal': {
                            display: 'none'
                          }
                        }}

                        type={isVisible ? 'text' : 'password'}
                        {...formik.getFieldProps('password')}

                        InputProps={{

                          endAdornment: (
                            <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                              {isVisible ? (
                                <Visibility
                                  onClick={() => {
                                    setVisible(false);
                                  }}
                                />
                              ) : (
                                <VisibilityOff
                                  onClick={() => {
                                    setVisible(true);
                                  }}
                                />
                              )}
                            </InputAdornment>
                          ),
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off'
                          },
                        }}
                        autoComplete="new-password"
                      />
                    )}
                    {employeeID && showButton && !emp?.user?.first_name && (
                      <Skeleton variant="text" sx={{ height: '65px' }} />
                    )}
                    {formik.errors.password && formik.touched.password ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.password}</Typography>
                    ) : null}
                  </Box>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControlLabel
                    checked={formik.values.agentAccess}
                    label="Agent Access"
                    name="agentAccess"
                    {...formik.getFieldProps('agentAccess')}
                    control={<Checkbox size="small" />}
                  />
                  <FormControlLabel
                    checked={formik.values.slfScore}
                    label="Self Score"
                    name="slfScore"
                    {...formik.getFieldProps('slfScore')}
                    control={<Checkbox size="small" />}
                  />
                  <FormControlLabel
                    checked={formik.values.prsnlGoals}
                    label="Personal Goals"
                    name="prsnlGoals"
                    {...formik.getFieldProps('prsnlGoals')}
                    control={<Checkbox size="small" />}
                  />
                  {aiEnabled && <FormControlLabel
                    checked={formik.values.is_ai_access}
                    label="Speech AI Hub"
                    name="is_ai_access"
                    {...formik.getFieldProps('is_ai_access')}
                    control={<Checkbox size="small" />}
                  />}
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Box sx={{ width: '50%' }}>
                    <InputLabel shrink id="groups">
                      Groups
                      <Typography variant="body" sx={{ color: setColor?.main, fontWeight: 'bold' }}>
                        &nbsp;*
                      </Typography>
                    </InputLabel>

                    <Box
                      sx={{
                        width: '100%',
                        borderRadius: 1,
                        overflow: 'hidden',
                        border: '1px solid #dcdde1',
                        height: '150px'
                      }}
                    >
                      <List sx={{ width: '100%', overflowY: 'scroll', height: 'inherit' }}>
                        {allGroup?.map((obj) => {
                          if (formGroup?.includes(obj.id)) {
                            return (
                              <ListItem
                                key={obj.id}
                                sx={{
                                  cursor: 'pointer',
                                  my: 1,
                                  userSelect: 'none',
                                  width: '100%',
                                  backgroundColor: setColor?.main,
                                  color: 'white',
                                  p: 1
                                }}
                                onClick={() => {
                                  handleAddGroups(obj.id);
                                }}
                              >
                                <ListItemText> {obj.name}</ListItemText>
                              </ListItem>
                            );
                          }

                          return (
                            <ListItem
                              key={obj.id}
                              sx={{
                                cursor: 'pointer',
                                my: 1,
                                width: '100%',
                                p: 1,
                                userSelect: 'none',
                                '&:hover': {
                                  backgroundColor: theme.palette.action.hover,
                                  color: theme.palette.text.primary
                                }
                              }}
                              onClick={() => {
                                handleAddGroups(obj.id);
                              }}
                            >
                              <ListItemText> {obj.name}</ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>

                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', display: grpErr }}>
                      *Required Fields
                    </Typography>
                  </Box>

                  <Box sx={{ width: '50%' }}>
                    <InputLabel shrink id="teams">
                      Teams
                      <Typography variant="body" sx={{ color: setColor?.main, fontWeight: 'bold' }}>
                        &nbsp;*
                      </Typography>
                    </InputLabel>
                    <Box
                      sx={{
                        width: '100%',
                        borderRadius: 1,
                        overflow: 'hidden',
                        border: '1px solid #dcdde1',
                        height: '150px'
                      }}
                    >
                      <List sx={{ width: '100%', overflowY: 'scroll', height: 'inherit' }}>
                        {
                          teamOption?.map((obj) =>
                            formTeam?.includes(obj.id) ? (
                              <ListItem
                                key={obj.id}
                                sx={{
                                  cursor: 'pointer',
                                  my: 1,
                                  width: '100%',
                                  userSelect: 'none',
                                  backgroundColor: theme.palette.action.selected,
                                  color: 'white',
                                  p: 1,
                                  '&::selection': { color: 'none', backgroundColor: 'transparent' }
                                }}
                                onClick={() => {
                                  handleAddTeams(obj.id);
                                }}
                              >
                                <ListItemText> {obj.name}</ListItemText>
                              </ListItem>
                            ) : (
                              <ListItem
                                key={obj.id}
                                sx={{
                                  cursor: 'pointer',
                                  my: 1,
                                  width: '100%',
                                  p: 1,
                                  userSelect: 'none',
                                  '&::selection': { color: 'none', backgroundColor: 'red' },
                                  '&:hover': {
                                    backgroundColor: theme.palette.action.hover,
                                    color: theme.palette.text.primary
                                  }
                                }}
                                onClick={() => {
                                  handleAddTeams(obj.id);
                                }}
                              >
                                <ListItemText> {obj.name}</ListItemText>
                              </ListItem>
                            )
                          )

                          // allTeam?.map((obj) => {
                          //     if (formGroup?.includes(obj?.groups?.id)) {
                          //         return formTeam?.includes(obj.id) ? <ListItem key={obj.id} sx={{ cursor: "pointer", my: 1, width: "100%", userSelect: "none", backgroundColor: theme.palette.action.selected, color: "white", p: 1, "&::selection": { color: "none", backgroundColor: "transparent" } }} onClick={() => { handleAddTeams(obj.id) }}>
                          //             <ListItemText> {obj.name}</ListItemText>
                          //         </ListItem> : <ListItem key={obj.id} sx={{ cursor: "pointer", my: 1, width: "100%", p: 1, userSelect: "none", "&::selection": { color: "none", backgroundColor: "red" }, "&:hover": { backgroundColor: theme.palette.action.hover, color: theme.palette.text.primary } }} onClick={() => { handleAddTeams(obj.id) }}>
                          //             <ListItemText> {obj.name}</ListItemText>
                          //         </ListItem>

                          //     }
                          //     return null
                          // })
                        }
                      </List>
                    </Box>
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', display: teamErr }}>
                      *Required Fields
                    </Typography>
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Box sx={{ width: '50%' }}>
                    {(!employeeID || (employeeID && emp?.user?.first_name)) && (
                      <TextField
                        sx={{ mt: 1 }}
                        label="External ID"
                        name="extID"
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off'
                          }
                        }}
                        {...formik.getFieldProps('extID')}
                      />
                    )}
                    {employeeID && showButton && !emp?.user?.first_name && (
                      <Skeleton variant="text" sx={{ height: '65px' }} />
                    )}
                    {formik.errors.extID && formik.touched.extID ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.extID}</Typography>
                    ) : null}
                  </Box>
                  <Box sx={{ width: '50%', display: employeeID ? 'block' : 'none' }}>
                    <FormControl fullWidth>
                      {(!employeeID || (employeeID && emp?.user?.first_name)) && (
                        <TextField
                          id="staff_type"
                          multiple={false}
                          select
                          label="Staff Type"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          name="staff_type"
                          {...formik.getFieldProps('staff_type')}
                        >
                          <MenuItem sx={{ color: 'text.primary' }} value={10}>
                            Employee
                          </MenuItem>
                          <MenuItem sx={{ color: 'text.primary' }} value={20}>
                            Supervisor
                          </MenuItem>
                          <MenuItem sx={{ color: 'text.primary' }} value={30}>
                            Advanced Supervisor
                          </MenuItem>
                        </TextField>
                      )}

                      {employeeID && showButton && !emp?.user?.first_name && (
                        <Skeleton variant="text" sx={{ height: '65px' }} />
                      )}
                    </FormControl>
                    {formik.errors.staff_type && formik.touched.staff_type ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.staff_type}</Typography>
                    ) : null}
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Box sx={{ width: '50%' }}>
                    <FormControl fullWidth>
                      {(!employeeID || (employeeID && emp?.user?.first_name)) && (
                        <TextField
                          id="sup"
                          multiple={false}
                          select
                          // required
                          label="Supervisors"
                          variant="outlined"
                          disabled={formTeam?.length === 0}
                          sx={{ mt: 1 }}
                          name="sup"
                          helperText={formTeam?.length === 0 ? 'Please select a group & team first.' : ''}
                          value={formTeam?.length === 0 ? null : formik.values.sup}
                          onChange={(e) => {
                            console.log('test');
                            formik.setFieldValue('sup', e?.target?.value);
                          }}
                          onBlur={() => {
                            formik.setFieldTouched('sup');
                          }}
                        // {...formik.getFieldProps('sup')}
                        >
                          {supervisorList?.map((obj) =>
                            obj?.id === emp?.supervisor?.id ? (
                              <MenuItem
                                onClick={() => {
                                  if (obj?.id === formik.values.sup) {
                                    formik.setFieldValue('sup', '');
                                  }
                                }}
                                sx={{ bgcolor: 'blue', color: 'white' }}
                                key={obj.id}
                                value={obj.id}
                              >{`${obj?.user__first_name} ${obj?.user__last_name}`}</MenuItem>
                            ) : (
                              <MenuItem
                                key={obj.id}
                                value={obj.id}
                              >{`${obj.user__first_name} ${obj?.user__last_name}`}</MenuItem>
                            )
                          )}
                        </TextField>
                      )}
                      {employeeID && showButton && !emp?.user?.first_name && (
                        <Skeleton variant="text" sx={{ height: '65px' }} />
                      )}
                    </FormControl>
                    {formik.errors.sup && formik.touched.sup ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.sup}</Typography>
                    ) : null}
                  </Box>

                  <Box sx={{ width: '50%' }}>
                    <FormControl fullWidth>
                      {(!employeeID || (employeeID && emp?.user?.first_name)) && (
                        <TextField
                          id="Employment type"
                          multiple={false}
                          select
                          // required
                          label="Employment type"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          name="emp_type"
                          {...formik.getFieldProps('emp_type')}
                        >
                          <MenuItem sx={{ color: 'text.primary' }} value={10}>
                            Full Time
                          </MenuItem>
                          <MenuItem sx={{ color: 'text.primary' }} value={20}>
                            Part Time
                          </MenuItem>
                          <MenuItem sx={{ color: 'text.primary' }} value={30}>
                            Full Time Work from Home
                          </MenuItem>
                        </TextField>
                      )}
                      {employeeID && showButton && !emp?.user?.first_name && (
                        <Skeleton variant="text" sx={{ height: '65px' }} />
                      )}
                      {formik.errors.emp_type && formik.touched.emp_type ? (
                        <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.emp_type}</Typography>
                      ) : null}
                    </FormControl>
                  </Box>
                </Stack>
                <Box sx={{ width: '100%', my: 1 }}>
                  {(!employeeID || (employeeID && emp?.user?.first_name)) && (
                    <Autocomplete
                      fullWidth
                      sx={{ width: '100%' }}
                      id="tags-outlined"
                      value={locationCountryValue || ''}
                      inputValue={inputCountryValue}
                      options={countryDetailsData}
                      getOptionLabel={(option) => option?.country_name || ''}
                      onChange={(event, newValue) => {
                        setLocationCountryValue(
                          countryDetailsData?.find((option) => option?.country_name === newValue?.country_name)
                        );
                        if (newValue?.country_name) {
                          formik.setFieldValue('location1', newValue?.country_name);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        // each key stroke call location API
                        handleFindLocationCountry(newInputValue);
                        setInputCountryValue(newInputValue);

                        if (newInputValue === '') {
                          formik.setFieldValue('location1', null);
                          setCountryDetailsData([]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Country*" fullWidth sx={{ width: '100%' }} />
                      )}
                    />
                  )}
                  {employeeID && showButton && !emp?.user?.first_name && (
                    <Skeleton variant="text" sx={{ height: '65px' }} />
                  )}

                  {formik.errors.location1 && formik.touched.location1 ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.location1}</Typography>
                  ) : null}
                </Box>
                <Box sx={{ width: '100%', my: 1 }}>
                  {(!employeeID || (employeeID && emp?.user?.first_name)) && (
                    <Autocomplete
                      fullWidth
                      sx={{ width: '100%' }}
                      id="tags-outlined2"
                      value={locationCityValue || ''}
                      inputValue={inputValue}
                      options={cityDetailsData}
                      getOptionLabel={(option) => option?.city_name || ''}
                      onChange={(event, newValue) => {
                        setLocationCityValue(
                          cityDetailsData?.find((option) => option?.city_name === newValue?.city_name)
                        );
                        if (newValue?.city_name) {
                          formik.setFieldValue('location2', newValue?.city_name);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        // each key stroke call location API
                        delayedQuery(formik.values.location1, newInputValue);
                        setInputValue(newInputValue);
                        if (newInputValue === '') {
                          formik.setFieldValue('location2', null);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} label="City*" fullWidth sx={{ width: '100%' }} />}
                    />
                  )}
                  {employeeID && showButton && !emp?.user?.first_name && (
                    <Skeleton variant="text" sx={{ height: '65px' }} />
                  )}

                  {formik.errors.location2 && formik.touched.location2 ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.location2}</Typography>
                  ) : null}
                </Box>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      fullWidth
                      label="Notes"
                      multiline
                      rows={2}
                      name="notes"
                      {...formik.getFieldProps('notes')}
                    />
                    {formik.errors.notes && formik.touched.notes ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.notes}</Typography>
                    ) : null}
                  </Box>
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ color: (theme) => theme.palette.text.buttonText }}
                onClick={() => {
                  formik.resetForm();
                  setFormTeam('');
                  setFormGroup('');
                  handleClose();
                }}
                color="inherit"
                className="no-disable"
              >
                Cancel
              </Button>
              {employeeID ? (
                <Button
                  sx={{
                    border: '1px solid #dddde7',
                    boxShadow: 'none',
                    borderRadius: '7px',
                    color: (theme) => theme.palette.text.buttonText,
                    backgroundColor: (theme) => theme.palette.background.paper,
                    '&:hover': {
                      color: (theme) => theme.palette.text.buttonText,
                      backgroundColor: (theme) => theme.palette.background.paper
                    }
                  }}
                  disabled={disBtn}
                  onClick={() => {
                    setIsSubmitted(true);
                    formik.handleSubmit();
                  }}
                  variant="contained"
                  className="halloween-animation"
                >
                  Update Employee
                  {disBtn && <CircularProgress size={14} sx={{ ml: 1 }} />}
                </Button>
              ) : (
                <Button
                  sx={{
                    border: '1px solid #dddde7',
                    boxShadow: 'none',
                    borderRadius: '7px',
                    color: (theme) => theme.palette.text.buttonText,
                    backgroundColor: (theme) => theme.palette.background.paper,
                    '&:hover': {
                      color: (theme) => theme.palette.text.buttonText,
                      backgroundColor: (theme) => theme.palette.background.paper
                    }
                  }}
                  disabled={disBtn}
                  onClick={() => {
                    setIsSubmitted(true);
                    formik.handleSubmit();
                  }}
                  variant="contained"
                  className="halloween-animation"
                >
                  Add Employee
                  {disBtn && <CircularProgress size={14} sx={{ ml: 1 }} />}
                </Button>
              )}
            </DialogActions>
          </form>
        </DisableWrapper>
      </Dialog>
    </div>
  );
}