import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import useLocalStorageState from 'use-local-storage-state'

// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Badge, Button, Typography, Tooltip, Menu, MenuItem, Avatar } from '@material-ui/core';
import { MailOutlineRounded, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowDown } from "@material-ui/icons";
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import { PATH_EMP, PATH_DASHBOARD } from "../../routes/paths";
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import Logo from "../../components/Logo";
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import { getMessageCount } from "../../_apis_/authApi.js/globalDashboardApi";
// import context 
import { SettingsContext } from "../../contexts/SettingsContext"
import pumpkin from '../../assets/Pumkin GIF.e3b4f9e2.gif'
import "../../assets/christmasCss.css";

// ----------------------------------------------------------------------

const DRAWER_WIDTH_XL = 280;
const DRAWER_WIDTH_LG = 200;
const COLLAPSE_WIDTH_LG = 48;
const COLLAPSE_WIDTH_XL = 60;

// const APPBAR_MOBILE = 64;
const APPBAR_MOBILE = 34;
// const APPBAR_DESKTOP = 92;
const APPBAR_DESKTOP = 70;
const isSup = [20, 30];
const isScore = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Score Only,Score & Calibrate"
const isStaff = "Global Admin,Group Admin,Team Admin,Internal,Advanced Supervisor,Supervisor,Employee"
const isScorecard = "Global Admin,Group Admin,Internal"
const isAnalytics = "Global Admin,Group Admin,Team Admin,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics"
const isReports = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics,Reports Only"
const isCalibrate = "Global Admin,Group Admin,Team Admin,Score & Calibrate,Calibrate Only,(Score, Calibrate, Report & Analytics)"
const isAdmin = 'Global Admin,Group Admin,Team Admin,Internal'
const isBilling = 'Global Admin,Group Admin,Team Admin'
const pathnameIndexTwo = [
  'calibrate_performance', 'edit_calibrate_performance', 'view_calibrate_performance', 'reviewPerformance', 'editScore', 'call_details', 'eventBuilder'
]

const RootStyle = styled(AppBar)(({ theme, path }) => ({
  boxShadow: 'none',
  zIndex: 500,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('lg')]: {
    width: !pathnameIndexTwo?.includes(path?.split("/")[2]) ? `calc(100% - ${DRAWER_WIDTH_LG}px)` : '100%'
  },
  [theme.breakpoints.up('xl')]: {
    width: !pathnameIndexTwo?.includes(path?.split("/")[2]) ? `calc(100% - ${DRAWER_WIDTH_XL}px)` : '100%'
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------



DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [companyData] = useLocalStorageState('companyData');
  // const [companyData] = 
  const { showHelpSupportPopUp, setShowHelpSupportPopUp } = React.useContext(SettingsContext)
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const { setColor, themeMode, setNotificationMsgCount, notificationMsgCount } = React.useContext(SettingsContext)
  const { isCollapse } = useCollapseDrawer();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [messageCount, setMessageCount] = React.useState(0);
  const [isMore, setIsMore] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const getThemeColor = () => {
    if (themeMode === 'light') {
      return setColor?.main
    }
    return setColor?.dark
  }

  const pathnameIndexOne = [
    'score', 'reports', 'calibration', 'calls', 'events'
  ]

  React.useEffect(() => {
    async function getCount() {
      const { data } = await getMessageCount();
      setNotificationMsgCount(data?.data);
    }
    getCount();
    setIsMore(false)

  }, [pathname, notificationMsgCount])

  return (
    <RootStyle
      path={pathname}
      sx={{
        ...(isCollapse && {
          width: pathnameIndexTwo?.includes(pathname?.split("/")[2]) ? '100%' : { lg: `calc(100% - ${COLLAPSE_WIDTH_LG}px)`, xl: `calc(100% - ${COLLAPSE_WIDTH_XL}px)` },
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        {(pathnameIndexOne?.includes(pathname?.split("/")[1]) && pathnameIndexTwo?.includes(pathname?.split("/")[2])) && <Link to={!loggedUserData?.staff?.staffType ? "/dashboard" : "/supervisor/dashboard"}>
          <Box sx={{ mr: 5, display: { xs: 'none', lg: 'block' }, width: { xs: '160px', lg: '100%' } }}><Logo /></Box>
        </Link>}
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} >
          {(pathnameIndexOne?.includes(pathname?.split("/")[1]) && pathnameIndexTwo?.includes(pathname?.split("/")[2])) && <Stack direction='row' alignItems='center' sx={{ width: '100%', display: { xs: 'none', lg: 'flex' } }}>
            <Tooltip title="Dashboard">
              <Link to={PATH_DASHBOARD.general.dashboard} style={{ textDecoration: "none" }}>
                <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.split("/")[1] === "supervisor" ? getThemeColor() : "transparent" }, color: pathname.split("/")[1] === "supervisor" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                  <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Dashboard</Typography>
                </Button>
              </Link>
            </Tooltip>
            {(isScore.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) && <Link to={PATH_DASHBOARD.general.score.index} style={{ textDecoration: "none" }}>
              <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.split("/")[1] === "score" ? getThemeColor() : "transparent" }, color: pathname.split("/")[1] === "score" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>

                <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Score</Typography>
              </Button>
            </Link>}
            {(isStaff.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) && <Tooltip title="Staff">
              <Link to={PATH_DASHBOARD.general.Staff} style={{ textDecoration: "none" }}>
                <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.split("/")[1] === "staff" ? getThemeColor() : "transparent" }, color: pathname.split("/")[1] === "staff" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                  <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Staff</Typography>
                </Button>
              </Link>
            </Tooltip>}
            {(isScorecard.includes(loggedUserData?.userLevel?.name) || loggedUserData?.staff?.staffType === 30) && <Tooltip title="Scorecard">
              <Link to={PATH_DASHBOARD.general.Scorecards} style={{ textDecoration: "none" }}>
                <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.split("/")[1] === "scorecards" ? getThemeColor() : "transparent" }, color: pathname === "/scorecards" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                  <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Scorecard</Typography>
                </Button>
              </Link>
            </Tooltip>}

            {(isCalibrate.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) && <Tooltip title="Calibration">
              <Link to={PATH_DASHBOARD.general.calibration.index} style={{ textDecoration: "none" }}>
                <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.split("/")[1] === "calibration" ? getThemeColor() : "transparent" }, color: pathname.split("/")[1] === "calibration" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                  <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Calibration</Typography>
                </Button>
              </Link>
            </Tooltip>}
            {(isReports.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) && <Tooltip title="Reports">
              <Link to={PATH_DASHBOARD.general.reports} style={{ textDecoration: "none" }}>
                <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.split("/")[1] === "reports" ? getThemeColor() : "transparent" }, color: pathname.split("/")[1] === "reports" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                  <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Reports</Typography>
                </Button>
              </Link>
            </Tooltip>}
            <Box sx={{ display: { xs: isMore ? 'block' : 'none', xl: 'block' } }} >
              {(isAnalytics.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) && <Tooltip title="Analytics">
                <Link to={PATH_DASHBOARD.general.analytics} style={{ textDecoration: "none" }}>
                  <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === "/scorecards" ? getThemeColor() : "transparent" }, color: pathname === "/scorecards" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                    <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Analytics</Typography>
                  </Button>
                </Link>
              </Tooltip>}
              {isAdmin.includes(loggedUserData?.userLevel?.name) && <Tooltip title="Admins">
                <Link to={PATH_DASHBOARD.general.admin} style={{ textDecoration: "none" }}>
                  <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === "/scorecards" ? getThemeColor() : "transparent" }, color: pathname === "/scorecards" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                    <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Admins</Typography>
                  </Button>
                </Link>
              </Tooltip>}
              {isBilling.includes(loggedUserData?.userLevel?.name) && <Tooltip title="Billings">
                <Link to={PATH_DASHBOARD.general.billing} style={{ textDecoration: "none" }}>
                  <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === "/scorecards" ? getThemeColor() : "transparent" }, color: pathname === "/scorecards" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                    <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Billings</Typography>
                  </Button>
                </Link>
              </Tooltip>}
              <Button endIcon={<KeyboardArrowDown fontSize='small' />} sx={{ color: "gray" }} size='small' onClick={handleOpenMenu}>
                <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Speech AI Hub</Typography>
              </Button>
            </Box>

            <IconButton disableFocusRipple disableRipple sx={{ '&:hover': { backgroundColor: 'transparent' }, display: { xs: 'block', xl: 'none' } }} onClick={() => setIsMore(!isMore)} >{isMore ? <KeyboardArrowLeft fontSize='small' /> : <KeyboardArrowRight fontSize='small' />} </IconButton>


          </Stack>}

          {(pathname === '/dashboard') && <Typography sx={{ color: '#fb5d2e', fontWeight: 500 }} variant='h5'>Welcome back, {loggedUserData?.firstName?.split("")[0]?.toUpperCase()}{loggedUserData?.firstName?.slice(1)}</Typography>}
        </Box>

        <Stack direction="row" alignItems="center" sx={{ display: { xs: isMore ? 'none' : 'flex', xl: 'flex' } }} spacing={{ xs: 0.5, sm: 1.5 }}>


          {(!pathname?.includes('support') && loggedUserData?.supportAccess) && <Button onClick={() => setShowHelpSupportPopUp(!showHelpSupportPopUp)} variant='outlined' sx={{ borderRadius: 5, boxShadow: 'none', mr: 1 }} size='small' > Support</Button>}

          {companyData?.inbox && <IconButton sx={{ mr: 1 }} onClick={() => { navigate("/notification"); }}>
            <Badge badgeContent={notificationMsgCount} color="primary">
              <Icon icon='ion:mail-outline' style={{ fontSize: '32px', fontWeight: 'lighter', color: themeMode === 'light' ? '#212668' : "#4b7bec" }} />
            </Badge>
          </IconButton>}

          <AccountPopover messageCount={notificationMsgCount} />

        </Stack>
      </ToolbarStyle>
      <Box>
        <Menu
          open={open}
          onClose={() => handleCloseMenu()}
          anchorEl={anchorEl}
          autoFocus={false}
          sx={{ border: '1px solid transparent', boxShadow: 0, minWidth: '150px' }}
        >
          <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/events') }}>

            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Events</Typography>
          </MenuItem>
          <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/calls') }}>

            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Calls</Typography>
          </MenuItem>
          <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/analyze?tab=one') }}>

            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Analyze</Typography>
          </MenuItem>
          <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/auto_qa_rules') }}>

            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Auto QA</Typography>
          </MenuItem>
          <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/coaching?csId=CD') }}>

            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Coaching</Typography>
          </MenuItem>
          <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/assignment') }}>

            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Assignment</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </RootStyle>
  );
}
