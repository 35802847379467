import { createContext, useState, useEffect, useContext } from 'react';
// REACT REDUX IMPORTS
import { useDispatch } from 'react-redux';
// REACT ROUTER IMPORTS
import { useSearchParams, useLocation } from 'react-router-dom';
// NPM IMPORTS
import moment from 'moment';
// REDUX ACTION IMPORTS
import { getSummary, getactivity } from "../redux/action/empAction";
import { empTaskDashboard } from "../redux/action/staffAction";
// API IMPORTS
import { supDashboardApi } from "../_apis_/authApi.js/empapi";
import { singleStaffApi, supScorecardListApi } from "../_apis_/authApi.js/staffApi";
// CONTEXT IMPORTS
import { StaffContext } from "./staffContext";

// import { getTaskApi } from '../_apis_/authApi.js/staffApi';


export const EmpContext = createContext();

export default function EmpProviders({ children, type }) {
    // REACT ROUTER DOM STATES
    const [searchParams, setSearchParams] = useSearchParams();
    const { pathname } = useLocation();
    // REDUX STATES
    const dispatch = useDispatch();
    // CONTEXT IMPORTS
    const { selectedSupGrp, selectedSupTm, selectedSupScrds, setSelectedSupGrp,
        setSelectedSupTm } = useContext(StaffContext);
    // const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REACT STATES
    const [fromDate, setFromDate] = useState();
    // const [dateData, setDateData] = useState(searchParams.get('date1') ? { date1: moment(searchParams.get('date1')).format('YYYY-MM-DD'), date2: moment(searchParams.get('date2')).format('YYYY-MM-DD') } : undefined);
    const [dateData, setDateData] = useState(searchParams.get('date1') ? { date1: moment(searchParams.get('date1')).format('YYYY-MM-DD'), date2: moment(searchParams.get('date2')).format('YYYY-MM-DD') } : undefined);
    const [toDate, setToDate] = useState();
    const [selectedItem, setselecteItem] = useState(2);
    const [selectedMenu, setselecteMenu] = useState(1);
    const [filter, setfilter] = useState("created_at");
    const [openSupGoal, SetSupGoal] = useState(false);
    const [openComment, SetOpenComment] = useState(false);
    const [openOne2One, SetOpenOne2One] = useState(false);
    const [openReview, SetOpenReview] = useState(false);
    const [supGrp, setSupGrp] = useState([]);
    const [supTeam, setSupTeam] = useState([]);
    const [supScreCrd, setSupScreCrd] = useState();
    const [supDashData, setSupDashData] = useState([]);
    const [scorecrdList, setScoreCardList] = useState();
    const [stffEmpId, setStffEmpId] = useState();
    const [id, setId] = useState();
    const [teamListSup, setTeamListSup] = useState([]);
    const [grpListSup, setGrpListSup] = useState([]);
    const [supSelectGrp, setSupSelectGrp] = useState([]);
    const [selectedTm, setSelectedTm] = useState([]);
    const [supSelectScrd, setSupSelectScrd] = useState([]);
    const [selectedScoreCard, setSelectedScoreCard] = useState([]);
    const [scrdDrpDwn, setScrdDropdown] = useState([]);
    const [tabValue, setTabValue] = useState('1');
    const [scoreLoader, setScoreLoader] = useState(false);
    const [scoreListLoader, setScoreListLoader] = useState(false);
    const [escalateDialog, setEscalateDialog] = useState(false);
    const [tscore, setTscore] = useState([]);
    const [myscore, setMyscore] = useState([]);
    const [xLabel, setXLabel] = useState([]);
    const [qualityIsScoreGraphLoading, setIsqualityScoreGraphLoading] = useState(false);



    useEffect(() => {
        const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

        // if (loggedUserData?.staff?.staffType === 10 || searchParams.get("stff_emp_id")) {
        if (loggedUserData?.staff?.staffType === 10) {
            setFromDate(moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'));
            setToDate(moment().format('YYYY-MM-DD'));
            setDateData({ date1: moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })
        }


        if ((loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && !searchParams.get('stff_emp_id')) {
            const grp = []; const tm = [];

            // loggedUserData?.groups?.map(obj => grp.push(obj?.id));
            // setSupGrp(grp);
            // loggedUserData?.teams?.map(obj => tm.push(obj?.id));
            // setSupTeam(tm);
            if (selectedSupGrp?.length > 0) {
                setSupGrp(selectedSupGrp);
            } else {

                setSupGrp(grp);
            }

            if (selectedSupTm?.length > 0) {
                setSupTeam(selectedSupTm);
            } else {
                setSupTeam(tm);
            }

            if (selectedSupScrds?.length > 0) {
                setSupSelectScrd(selectedSupScrds)
            } else {
                setSupSelectScrd([]);
            }

            if (!fromDate) {
                setFromDate(moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'));
                setToDate(moment().format('YYYY-MM-DD'));
                setDateData({ date1: moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })
            }
        }

        const fetchSingleStaff = async () => {
            const { data } = await singleStaffApi(searchParams.get("stff_sup_id"));
            setStffEmpId(data);
            const grp = []; const tm = [];
            // if (selectedSupGrp?.length > 0) {
            //     setSupGrp(selectedSupGrp);
            // } else {
            //     setSelectedSupGrp([])
            //     setSupGrp(grp);
            // }

            // if (selectedSupTm?.length > 0) {
            //     setSupTeam(selectedSupTm);
            // } else {
            //     setSelectedSupTm([])
            //     setSupTeam(tm);
            // }
            setSelectedSupGrp([])
            setSupGrp(grp);
            setSelectedSupTm([])
            setSupTeam(tm);

            if (selectedSupScrds?.length > 0) {
                setSupSelectScrd(selectedSupScrds)
            } else {
                setSupSelectScrd([]);
            }




            // data?.user?.groups?.map(obj => grp.push(obj?.id));
            // data?.user?.teams?.map(obj => tm.push(obj?.id));


            setTabValue("1");
            setGrpListSup(data?.user?.groups);
            setTeamListSup(data?.user?.teams);
            // setSupSelectScrd([]);
            // setSelectedScoreCard([]);
            // setFromDate(moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'));
            // setFromDate(moment(searchParams.get('date1')).format('YYYY-MM-DD'));
            // setToDate(moment().format('YYYY-MM-DD'));
            // setToDate(moment(searchParams.get('date2')).format('YYYY-MM-DD'));
            // setDateData({ date1: moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })
            // setDateData({ date1: moment(searchParams.get('date1')).format('YYYY-MM-DD'), date2: moment(searchParams.get('date2')).format('YYYY-MM-DD') })
        }



        if (searchParams.get("stff_sup_id") && searchParams.get("stff_sup_id") !== 'undefined') {
            console.log('sup', searchParams.get("stff_sup_id"))
            fetchSingleStaff()

        }


    }, [searchParams.get("stff_sup_id"), searchParams.get("stff_emp_id")]);



    // useEffect(() => {
    //     (async function () {
    //         if (searchParams.get("stff_sup_id")) {
    //             const { data } = await singleStaffApi(searchParams.get("stff_sup_id"));
    //             const grp = []; const tm = [];
    //             data?.user?.groups?.map(obj => grp.push(obj?.id));
    //             data?.user?.teams?.map(obj => tm.push(obj?.id));
    //             setSupGrp(grp);
    //             setSupTeam(tm);
    //             const res = await supScorecardListApi(searchParams.get("stff_sup_id"), dateData?.date1, dateData?.date2, supSelectScrd, grp, tm);
    //             setScoreCardList(res?.data?.results);
    //             setSupScreCrd([res?.data?.results[0]?.id]);
    //             setTabValue("1");
    //             setGrpListSup(data?.user?.groups);
    //             setTeamListSup(data?.user?.teams);
    //         }
    //     })()
    // }, [searchParams.get("stff_sup_id"), dateData])

    useEffect(() => {
        (async function () {
            if (searchParams.get("stff_emp_id")) {
                const { data } = await singleStaffApi(searchParams.get("stff_emp_id"));
                setStffEmpId(data);
            }

            // if (searchParams.get("stff_sup_id")) {
            //     const { data } = await singleStaffApi(searchParams.get("stff_sup_id"));
            //     setStffEmpId(data);
            // }
        })()
    }, [id])


    useEffect(() => {

        if (dateData && pathname?.split("/")[2] === "dashboard" && pathname?.split("/")[1] === "supervisor" && (type === "sup" || searchParams.get("stff_sup_id"))) {
            setSupDashData([]);
            const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
            (async function () {
                try {
                    setScoreListLoader(true);
                    setScoreCardList(undefined);
                    const res = await supScorecardListApi(loggedUserData?.staff?.id || searchParams.get("stff_sup_id"), dateData?.date1, dateData?.date2, supSelectScrd, supGrp, supTeam, filter);
                    setScoreCardList(res?.data?.results);
                    setScoreListLoader(false);
                    setSupScreCrd([res?.data?.results[0]?.id]);
                    setTabValue("1");
                } catch (err) {
                    setScoreListLoader(false);
                    setScoreCardList(undefined);
                }

            })()

        }
        if (searchParams.get("stff_emp_id")) {
            setId(searchParams.get("stff_emp_id"));
        }
    }, [pathname])

    useEffect(() => {

        if ((dateData && type === "emp") || (searchParams.get("stff_emp_id") && dateData?.date1)) {
            const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
            if (pathname.split('/')[2] === 'dashboard' || pathname.split('/')[2] === 'tasks') {
                dispatch(empTaskDashboard(dateData?.date1, dateData?.date2, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id))
            }
            if (pathname.split('/')[2] === 'activity') {
                dispatch(getactivity(dateData?.date1, dateData?.date2, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, 1, false));
            }
            if (pathname.split('/')[2] === 'dashboard') {
                dispatch(getactivity(dateData?.date1, dateData?.date2, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, 1, 5));
                setXLabel([]);
                setTscore([]); setMyscore([]);
                setIsqualityScoreGraphLoading(true)
                dispatch(getSummary(searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, dateData?.date1, dateData?.date2, filter, setIsqualityScoreGraphLoading));
            }


        }
        if (searchParams.get("stff_emp_id")) {
            setId(searchParams.get("stff_emp_id"));
        }
    }, [dateData, filter, pathname])

    useEffect(() => {
        if (dateData && (type === "sup" || searchParams.get("stff_sup_id")) && supScreCrd && supTeam) {
            const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
            (async function () {
                try {
                    setScoreLoader(true);
                    setSupDashData([]);
                    const res = await supDashboardApi(loggedUserData?.staff?.id || searchParams.get("stff_sup_id"), dateData?.date1, dateData?.date2, supGrp, supTeam, supScreCrd, filter)
                    setSupDashData(res?.data?.results);
                    setScoreLoader(false);
                } catch (err) {
                    setSupDashData([]);
                    setScoreLoader(false);
                }


            })()
        }
    }, [supScreCrd])


    return (
        <>
            <EmpContext.Provider value={{
                name: "test", strtDate: fromDate, setStrtDate: setFromDate,
                datesData: dateData, setDatesData: setDateData,
                endDate: toDate, setEndDate: setToDate, dateItem: selectedItem, setDateItem: setselecteItem,
                filterby: filter, setFilterBy: setfilter, activeMenu: selectedMenu, setActiveMenu: setselecteMenu,
                supGoal: openSupGoal, setSupGoal: SetSupGoal,
                acceptComment: openComment, setAcceptComment: SetOpenComment,
                acceptReview: openReview, setAcceptReview: SetOpenReview,
                acceptOne2One: openOne2One, setAcceptOne2One: SetOpenOne2One,
                grpSup: supGrp, setGrpSup: setSupGrp,
                tmSup: supTeam, setTmSup: setSupTeam,
                scCrd: supScreCrd, setScScrd: setSupScreCrd,
                screList: scorecrdList, setScreList: setScoreCardList,
                dataSupDash: supDashData,
                IdEmp: stffEmpId,
                listTeam: teamListSup, grpList: grpListSup,
                selectedGrp: supSelectGrp, setSelectedGrp: setSupSelectGrp,
                selectScrd: supSelectScrd, setSelectScrd: setSupSelectScrd,
                selectedScoreCard, setSelectedScoreCard,
                selectedTm, setSelectedTm,
                scrdDrpDwn, setScrdDropdown,
                tabValue, setTabValue,
                supDashData, setSupDashData,
                scoreLoader, setScoreLoader,
                scoreListLoader, setScoreListLoader,
                escalateDialog, setEscalateDialog,
                tscore, setTscore,
                myscore, setMyscore,
                xLabel, setXLabel,
                qualityIsScoreGraphLoading, setIsqualityScoreGraphLoading


            }}>
                {children}
            </EmpContext.Provider>
        </>
    )
}