import {
    summary, empActivity
} from '../types/typeEmp';
import { empdashboardApi, empActivityApi } from '../../_apis_/authApi.js/empapi';

// ---------*STAFF ACTION (emp & supervisor)*-------------

export const getSummary = (id, date1, date2, filter, setIsqualityScoreGraphLoading) =>
    async (dispatch) => {
        try {
            const { data } = await empdashboardApi(id, date1, date2, filter);
            dispatch(setAllSummary(data))
            setIsqualityScoreGraphLoading(false)
        } catch (err) {
            setIsqualityScoreGraphLoading(false)
        }
    }

const setAllSummary = (data = null) => {
    if (data) {
        return {
            type: summary,
            payload: data
        };
    }
    return {
        type: summary,
        payload: data
    }
}

export const getactivity = (date1, date2, id, pgNo, isDashboard) =>
    async (dispatch) => {
        const { data } = await empActivityApi(date1, date2, id, pgNo, isDashboard);
        // console.log(data);
        dispatch(setAllActivity(data));
    }

const setAllActivity = (data = null) => {
    if (data) {
        return {
            type: empActivity,
            payload: data
        };
    }
    return {
        type: empActivity,
        payload: data
    }
}











