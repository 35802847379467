import React from 'react';
// REDUX IMPORTS
import { useSelector } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Box,
    Button,
    Chip,
    IconButton,
    Paper,
    Table,
    TableBody,
    Typography,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    tableCellClasses,
    Tooltip
} from '@material-ui/core';
import { Flag, WarningAmber, Star, Score, ArrowDownward, ArrowUpward } from '@material-ui/icons';

export default function ScoreSummary({ theme, themeColor }) {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REDUX STATES
    const summary = useSelector(state => state.empSummary);
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();
    // REACT STATES
    const [scorecard, setscorecard] = React.useState([]);
    const [showIcon, setShowIcon] = React.useState(false);
    const [tableOrder, setTableOrder] = React.useState(false);

    const handleTableOrder = () => {
        setTableOrder(!tableOrder)
        setscorecard(scorecard.reverse());
    }

    React.useEffect(() => {
        if (summary) {
            setscorecard(summary.score_scorecard);
            // console.log(summary.score_scorecard);
        }
    }, [summary])
    return (
        <>
            <TableContainer component={Paper} elevation={5} sx={{ p: 2, borderRadius: '5px' }}>
                <Table size='small' sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{
                            [`& .${tableCellClasses.root}`]: {
                                py: 1
                            },
                        }}>
                            <TableCell onMouseLeave={() => setShowIcon(false)} onMouseEnter={() => setShowIcon(true)}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>
                                        Scorecards
                                    </Typography>&nbsp;
                                    {showIcon && <Tooltip title='sort' ><IconButton onClick={() => handleTableOrder()} >{tableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}</IconButton></Tooltip>}
                                </Box>

                            </TableCell>
                            <TableCell align="right">Reviewed</TableCell>
                            <TableCell align="right">1:1</TableCell>
                            <TableCell align="right"><Flag /></TableCell>
                            <TableCell align="right"><WarningAmber /></TableCell>
                            <TableCell align="right"><Star /></TableCell>
                            <TableCell align="right">Team Score</TableCell>
                            <TableCell align="center">My Score</TableCell>
                            <TableCell align="right">
                                <Link to={searchParams.get('stff_emp_id') ? `/employee/scores?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=True` : "/employee/scores"} style={{ color: theme === 'light' ? themeColor?.main : themeColor?.dark }}>
                                    All Scores
                                </Link>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scorecard?.length > 0 &&
                            scorecard?.slice(0, 7)?.map((obj, index) =>
                                <TableRow
                                    key={index}
                                    sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: (theme) => `1px solid ${(index !== scorecard?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                        },
                                    }}
                                >
                                    <TableCell align="left" component="th" scope="row">
                                        {obj.score_card__name}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.reviewed}
                                    </TableCell>
                                    <TableCell align="right" component="th" scope="row">
                                        {obj.onetoone_req}
                                    </TableCell>
                                    <TableCell align="right" component="th" scope="row">
                                        {obj.fail_section_count}
                                    </TableCell>
                                    <TableCell align="right" component="th" scope="row">
                                        {obj.fail_all_count}
                                    </TableCell>
                                    <TableCell align="right" component="th" scope="row">
                                        {obj.kudos}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {(obj?.team_score_sum - obj?.team_na_sum) === 0 && <>0.00%</>}
                                        {(obj?.team_score_sum - obj?.team_na_sum) !== 0 && <>
                                            {`${Number?.isNaN(((obj?.team_total_score / (obj?.team_score_sum - obj?.team_na_sum)) * 100)?.toFixed(2)) ? 0 : ((obj?.team_total_score / (obj?.team_score_sum - obj?.team_na_sum)) * 100)?.toFixed(2)}%`}

                                        </>}

                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Chip label={Number?.isNaN(((100 * obj?.total_score) / obj.max_score)) ? `0 %` : `${((100 * obj?.total_score) / obj.max_score).toFixed(1)}%`} color='warning' sx={{ color: 'white' }} />
                                    </TableCell>
                                    <TableCell align="right" component="th" scope="row">
                                        {loggedUserData?.staff?.staffType === 10 && <Link to={`/employee/scores?tab=${index + 1}`} style={{ textDecoration: "none" }}>
                                            <Button variant="contained" sx={{ backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: { lg: '12px', xl: '14px' }, border: '1px solid #dddde7' }}>
                                                View Scores
                                            </Button>
                                        </Link>}
                                        {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={`/employee/scores?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=False&tab=${index + 1}`} style={{ textDecoration: "none" }}>
                                            <Button variant="contained" sx={{ backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: { lg: '12px', xl: '14px' }, border: '1px solid #dddde7' }}>
                                                View Scores
                                            </Button>
                                        </Link>}
                                        {!loggedUserData?.staff && <Link to={`/employee/scores?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=True&tab=${index + 1}`} style={{ textDecoration: "none" }}>
                                            <Button variant="contained" sx={{ backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: { lg: '12px', xl: '14px' }, border: '1px solid #dddde7' }}>
                                                View Scores
                                            </Button>
                                        </Link>}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                {scorecard?.length === 0 && <Paper elevation={0} sx={{ minHeight: "100px", width: "100%", p: 2 }}>
                    <Typography gutterBottom align="center" variant="subtitle1" sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Score /> &nbsp;No Data Found For Data Range Selected.
                    </Typography>
                </Paper>}
            </TableContainer>

        </>
    )
}